import { useAllTrainees, useMergeRequestSynchronization } from "@/services/message-service";

export const useTraineesViewModel = () => {
    const trainees = useAllTrainees();
    const mergeRequestSynchronization = useMergeRequestSynchronization();

    return {
        trainees: trainees.data.value.trainees,
        synchronizeMergeRequests: () => mergeRequestSynchronization.mutateAsync(),
        isPending: mergeRequestSynchronization.isPending,
    };
};
