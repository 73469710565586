import { CurrentStateOfProjectorsQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SaveIcon from "@mui/icons-material/Save";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { Card, CardActions, CardContent, CardHeader, IconButton, Tooltip, Typography } from "@mui/material";

type ProjectorState = ArrayElement<CurrentStateOfProjectorsQueryResult>;

type ProjectorProps = {
    projector: ProjectorState;
    isUpToDate: boolean;
    showSnapshot: <T>(title: string, snapshot: T) => void;
};
export function Projector(props: Readonly<ProjectorProps>) {
    const { projector, isUpToDate, showSnapshot } = props;

    return (
        <Card
            data-cy="cardRoot"
            raised
            sx={{
                backgroundColor: isUpToDate ? "initial" : "red",
            }}>
            <CardHeader
                data-cy="projectorCardHeader"
                title={projector.name}
                titleTypographyProps={{
                    variant: "caption",
                    align: "center",
                }}
            />
            <CardContent>
                <Typography
                    data-cy="Content"
                    variant="h1"
                    align="center"
                    title={`Version ${projector.currentSnapshot.version}, Sequence number ${projector.currentSnapshot.sequenceNumber}`}>
                    {projector.currentSnapshot.version} / {projector.currentSnapshot.sequenceNumber}
                </Typography>
            </CardContent>
            <CardActions>
                <Tooltip title="Show current state">
                    <span>
                        <IconButton color="primary" onClick={() => showSnapshot("Current state", projector.currentSnapshot)}>
                            <QueryStatsIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Show stored snapshot">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={() => showSnapshot("Current stored snapshot", projector.storedSnapshot)}
                            disabled={projector.storedSnapshot === undefined}>
                            <SavedSearchIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Create a new snapshot">
                    <span>
                        <IconButton color="secondary" disabled={projector.snapshotStrategy === "Never"}>
                            <SaveIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </CardActions>
        </Card>
    );
}
