import { EmptyGuid } from "@/constants";
import { EditTrackQueryResult, useEditModule, useSaveModule } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { useState } from "react";

type Module = ArrayElement<EditTrackQueryResult["modules"]>;
type Variant = Module["defaultVariant"];

export const useEditModuleViewModel = (module: Module) => {
    const [draft, setDraft] = useState({ ...module });
    const saveModule = useSaveModule();
    const model = useEditModule({ id: module.id });

    const blogPosts = model.data.value.blogPosts.map((path) => path.split("/").slice(-1)[0]).sort((a, b) => a.localeCompare(b));

    const selectNewVariant = (variants: Variant[]) => {
        return variants.length > 0 ? variants[0] : null;
    };

    return {
        blogPosts: blogPosts,
        moduleVariants: model.data.value.moduleVariants,
        module: draft,
        rename(name: string) {
            setDraft({ ...draft, name: name });
        },
        relocate: (blogPost: string) => {
            setDraft({ ...draft, blogPost: blogPost });
        },
        cancel: () => setDraft({ ...module }),
        isValid() {
            return !!draft.name && !!draft.blogPost && !!draft.defaultVariant;
        },
        saveAsync: async () => {
            await saveModule.mutateAsync(draft);
            if (draft.id === EmptyGuid) {
                setDraft({ ...module });
            }
        },
        reset() {
            setDraft({ ...module });
        },
        toggleVariant(variant: Variant) {
            const newVariants = draft.variants.includes(variant)
                ? draft.variants.filter((v) => v !== variant)
                : [...draft.variants, variant];

            const defaultVariant = newVariants.includes(draft.defaultVariant)
                ? draft.defaultVariant
                : selectNewVariant(newVariants);

            setDraft({ ...draft, variants: newVariants, defaultVariant: defaultVariant! });
        },
        setDefaultVariant(variant: Variant) {
            setDraft({ ...draft, defaultVariant: variant });
        },
    };
};
