import loadable from "@loadable/component";
import roadmap from "./roadmap.mmd?raw";
import { Container } from "@mui/material";
import { LoadingIndicator } from "@/modules/common/components/LoadingIndicator";

const Mermaid = loadable(() => import("@/modules/common/components/Mermaid"), {
    resolveComponent: (components) => components.Mermaid,
    fallback: <LoadingIndicator />,
});

export const Roadmap = () => {
    return (
        <Container data-cy="roadmap_container" maxWidth={false}>
            <Mermaid>{roadmap}</Mermaid>
        </Container>
    );
};
