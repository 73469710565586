import { useAuthenticationViewModel } from "@/modules/common/auth/viewModels/useAuthenticationViewModel";
import { Alert, Avatar, Box, Container, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Navigate } from "react-router-dom";
import { useAuthenticationContext } from "@/modules/common/auth/useAuthenticationContext";
import { LoadingButton } from "@mui/lab";

export function ChangePassword() {
    const viewModel = useAuthenticationViewModel();
    const authenticationContext = useAuthenticationContext();

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [result, setResult] = useState<"success" | "error" | "loading" | undefined>();
    const [validationMessages, setValidationMessages] = useState<string[]>([]);

    if (authenticationContext.authenticatedWith !== "UsernamePassword") {
        return <Navigate to="/" />;
    }

    async function onChangePassword(e: FormEvent) {
        e.preventDefault();

        setResult("loading");
        const result = await viewModel.changePasswordAsync(currentPassword, newPassword, passwordConfirmation);
        if (result.isSuccess) {
            setResult("success");
            setValidationMessages([]);
        } else {
            setResult("error");
            setValidationMessages(result.errors.map((error) => error.message));
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                    <LockResetIcon />
                </Avatar>
                <Box component="form" onSubmit={(e) => onChangePassword(e)} noValidate sx={{ mt: 1 }}>
                    {result === "success" && <Alert severity="success">Password has been changed</Alert>}
                    {result === "error" && (
                        <Alert severity="error">
                            <ul>
                                {validationMessages.map((message) => (
                                    <li key={message}>{message}</li>
                                ))}
                            </ul>
                        </Alert>
                    )}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Current password"
                        type="password"
                        autoComplete="current-password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <TextField
                        error={newPassword !== passwordConfirmation && newPassword.length > 0 && passwordConfirmation.length > 0}
                        helperText={newPassword === passwordConfirmation ? "" : "Passwords don't match"}
                        margin="normal"
                        required
                        fullWidth
                        label="New password"
                        type="password"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        error={newPassword !== passwordConfirmation && newPassword.length > 0 && passwordConfirmation.length > 0}
                        helperText={newPassword === passwordConfirmation ? "" : "Passwords don't match"}
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm password"
                        type="password"
                        autoComplete="new-password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                    <LoadingButton
                        type="submit"
                        loading={result === "loading"}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        Change password
                    </LoadingButton>
                </Box>
            </Box>
        </Container>
    );
}
