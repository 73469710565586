import { KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { darken, styled } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: darken(theme.palette.secondary.main, 0.5),
    "&:hover": {
        backgroundColor: darken(theme.palette.secondary.main, 0.5),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

export function SearchInput() {
    const [term, setTerm] = useState("");
    const navigate = useNavigate();

    function onSearch(e: KeyboardEvent) {
        if (e.key === "Enter" && term !== "") {
            e.preventDefault();
            setTerm("");
            navigate(`/academy/search/${encodeURIComponent(term)}/1`);
        }
    }

    return (
        <Search data-cy="search_box">
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                data-cy="search_mark"
                placeholder="Search..."
                inputProps={{ "data-cy": "input", "aria-label": "search" }}
                onChange={(e) => setTerm(e.target.value)}
                value={term}
                onKeyDown={(e) => onSearch(e)}
            />
        </Search>
    );
}
