import { BlogPostsListMenuButton } from "@/modules/admin/blog_posts/BlogPostsListMenuButton";
import { Suspender } from "@/modules/common/components/Suspender";
import { useRetrieveAllBlogPosts } from "@/services/message-service";
import { Box, Container, Divider, Drawer, ListItem, ListItemButton, ListItemText, Toolbar } from "@mui/material";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export function BlogPostIndex() {
    const blogPostsRetrieveResults = useRetrieveAllBlogPosts();
    const blogPosts = blogPostsRetrieveResults.data.value;

    const [sideBarOpen, setSideBarOpen] = useState(false);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setSideBarOpen(!sideBarOpen);
    };

    return (
        <Container maxWidth={false}>
            <Box sx={{ display: "flex" }}>
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <Suspender>
                        <Outlet />
                    </Suspender>
                </Box>

                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: 0,
                        zIndex: 999,
                    }}>
                    <BlogPostsListMenuButton toggleSidebar={() => toggleSidebar()} />
                </Box>

                <Drawer
                    data-cy="drawer"
                    sx={{
                        width: 500,
                        flexShrink: 0,
                        marginTop: "164px",
                        "& .MuiDrawer-paper": {
                            width: 500,
                            boxSizing: "border-box",
                        },
                    }}
                    variant="temporary"
                    anchor="right"
                    open={sideBarOpen}
                    onClose={() => toggleSidebar()}>
                    <Toolbar />
                    <Toolbar />
                    <Divider />
                    {blogPosts
                        .toSorted((a, b) => a.title.localeCompare(b.title))
                        .map((blogPost) => (
                            <ListItem sx={{ fontWeight: "bold" }} key={blogPost.slug} disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        setSideBarOpen(false);
                                        navigate(`/mentoring/blog_posts/${blogPost.slug}`);
                                    }}>
                                    <ListItemText primary={blogPost.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                </Drawer>
            </Box>
        </Container>
    );
}
