import { useAuthenticationViewModel } from "@/modules/common/auth/viewModels/useAuthenticationViewModel";
import LogoutIcon from "@mui/icons-material/Logout";
import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SchoolIcon from "@mui/icons-material/School";
import LockResetIcon from "@mui/icons-material/LockReset";

const { height: screenHeight, width: screenWidth } = window.screen;
const popupWidth = Math.min(500, screenWidth - 40);
const popupHeight = Math.min(550, screenHeight - 40);
const features = [
    "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no",
    "width=" + popupWidth,
    "height=" + popupHeight,
    "top=" + (screenHeight / 2 - popupHeight / 2),
    "left=" + (screenWidth / 2 - popupWidth / 2),
].join();

export function Authentication() {
    const viewModel = useAuthenticationViewModel();
    const navigate = useNavigate();

    if (viewModel.isAuthenticated) {
        return (
            <>
                {viewModel.authenticatedWith === "UsernamePassword" && (
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => navigate("/academy/change_password")}
                        startIcon={<LockResetIcon />}>
                        Change password
                    </Button>
                )}
                <Button
                    data-cy="logout"
                    variant="text"
                    color="inherit"
                    onClick={() => viewModel.logoutAsync()}
                    startIcon={<LogoutIcon />}>
                    Log out
                </Button>
            </>
        );
    }

    return (
        <ButtonGroup variant="text" color="inherit">
            {viewModel.supportedAuthenticationSchemes.includes("Development") && (
                <>
                    <Button onClick={() => viewModel.loginAsTraineeAsync()} startIcon={<SchoolIcon />}>
                        Trainee
                    </Button>

                    <Button onClick={() => viewModel.loginAsAdminAsync()} startIcon={<ManageAccountsIcon />}>
                        Admin
                    </Button>
                </>
            )}

            <Button
                onClick={() => {
                    window.open("about:blank", "google_authentication", features);
                    viewModel.challengeAsync();
                }}
                startIcon={<GoogleIcon />}>
                @sogyo.nl
            </Button>

            <Button onClick={() => navigate("/login")} startIcon={<LoginIcon />}>
                External
            </Button>
        </ButtonGroup>
    );
}
