import { LaunchSharp } from "@mui/icons-material";
import { Link as MUILink, List, ListItem } from "@mui/material";
import { Container } from "@mui/system";

type LinkInfo = {
    name: string;
    href: string;
    description: string;
};

export function SogyoSites() {
    const links: Array<LinkInfo> = [
        { name: "Intranet", href: "https://intranet.sogyo.nl", description: "Everything HR related." },
        { name: "Git", href: "https://git.sogyo.nl", description: "Source code version control." },
        {
            name: "Master courses Drive",
            href: "https://drive.google.com/drive/u/0/folders/0AOL57-RXZYM4Uk9PVA",
            description: "E-books, Modules, VMP's, etc.",
        },
    ];
    return (
        <Container data-cy="sogyo_sites_main">
            <List>
                {links.map((link) => (
                    <ListItem key={link.name}>
                        <MUILink href={link.href} target="_blank">
                            {link.name}
                            <LaunchSharp />
                        </MUILink>

                        <span>{link.description}</span>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
}
