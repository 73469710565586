import { EmptyGuid } from "@/constants";
import { Link } from "@/modules/academy/Link";
import { useOverviewViewModel } from "@/modules/admin/tracks/viewModels/useOverviewViewModel";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Overview() {
    const viewModel = useOverviewViewModel();
    const navigate = useNavigate();

    return (
        <Grid container spacing={2}>
            {viewModel.tracks.map((track) => (
                <Grid key={track.id ?? EmptyGuid} item xs={4}>
                    <Card variant="elevation">
                        <CardHeader
                            data-cy="CardHeader"
                            title={track.name}
                            sx={{
                                bgcolor: "primary.main",
                                color: "primary.contrastText",
                                textTransform: "capitalize",
                            }}
                        />
                        <CardContent data-cy="CardBody">
                            <Link href={"/academy/tracks/" + track.slug}>
                                https://academy.sogyo.nl/academy/tracks/{track.slug}
                            </Link>

                            <ol data-cy="list">
                                {track.modules.map((module) => (
                                    <li key={module.name}>{module.name}</li>
                                ))}
                            </ol>
                        </CardContent>
                        <CardActions>
                            <IconButton onClick={() => navigate(`./edit/${track.id}`)} color="primary">
                                <EditIcon />
                                <Typography>Edit</Typography>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            <Grid item xs={4}>
                <Card variant="elevation">
                    <CardHeader
                        sx={{
                            bgcolor: "secondary.main",
                            color: "secondary.contrastText",
                            textTransform: "capitalize",
                        }}
                    />
                    <CardActions>
                        <IconButton onClick={() => navigate(`./edit/${EmptyGuid}`)} color="primary">
                            <AddIcon />
                            <Typography>Create</Typography>
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}
