import { useAddBlogPost } from "@/services/message-service";

export const useCreateBlogPostViewModel = () => {
    const addBlogPost = useAddBlogPost();

    return {
        saveAsync: async (title: string, slug: string, markdown: string) => {
            await addBlogPost.mutateAsync({
                title: title,
                slug: slug,
                markdown: markdown,
            });
        },
    };
};
