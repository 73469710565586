import { useCurrentStateOfProjectors } from "@/services/message-service";

export const useProjectorOverviewViewModel = () => {
    const projectorsState = useCurrentStateOfProjectors();

    return {
        projectors: projectorsState.data.value,
        lastSequenceNumber: Math.max(...projectorsState.data.value.map((value) => value.currentSnapshot.sequenceNumber)),
    };
};
