// This file is generated by /src/Timburr.CodeGeneration/Templates/typescript.scriban
// Generation date:2024-06-05 07:17:20
import axios, { isAxiosError } from "axios";
import { z } from "zod";
import { useMutation, useQueryClient, useSuspenseQuery, queryOptions, UseMutationResult } from "@tanstack/react-query";

const api = axios.create({
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
    withCredentials: true,
    maxRedirects: 0,
});

export const MessageKeys = {
    applicationVersion: "application-version",
    allUsers: "all-users",
    allTrainees: "all-trainees",
    getTrackProgressForTrainee: "get-track-progress-for-trainee",
    editModule: "edit-module",
    editTrack: "edit-track",
    getAllModuleVariants: "get-all-module-variants",
    trackOverview: "track-overview",
    challenge: "challenge",
    currentUser: "current-user",
    search: "search",
    currentStateOfProjectors: "current-state-of-projectors",
    createUnitTest: "create-unit-test",
    eventLogs: "event-logs",
    getBlogPostBySlug: "get-blog-post-by-slug",
    retrieveAllBlogPosts: "retrieve-all-blog-posts",
    slugsInUse: "slugs-in-use",
    changePassword: "change-password",
    createNewUser: "create-new-user",
    grantAdministratorRole: "grant-administrator-role",
    resetPassword: "reset-password",
    revokeAdministratorRole: "revoke-administrator-role",
    unverifyEmailAddress: "unverify-email-address",
    verifyEmailAddress: "verify-email-address",
    assignTrack: "assign-track",
    completeModule: "complete-module",
    endTrack: "end-track",
    startModule: "start-module",
    saveModule: "save-module",
    saveTrack: "save-track",
    enrichUserWithClaims: "enrich-user-with-claims",
    login: "login",
    logout: "logout",
    mergeRequestSynchronization: "merge-request-synchronization",
    addBlogPost: "add-blog-post",
    updateBlogPost: "update-blog-post",
    importFromAurorus: "import-from-aurorus",
} as const;

const Reason = z.object({
    message: z.string(),
});

const Result = z.object({
    isFailed: z.boolean(),
    isSuccess: z.boolean(),
    reasons: z.array(Reason),
    errors: z.array(Reason),
    successes: z.array(Reason),
});
type Result = z.infer<typeof Result>;

function wrapMutation<TData, TError, TVariables, TContext>(mutation: UseMutationResult<TData, TError, TVariables, TContext>) {
    const wrapper = async (variables: TVariables) => {
        try {
            const result = await mutation.mutateAsync(variables);

            return result;
        } catch (error) {
            if (isAxiosError(error)) {
                const result = Result.safeParse(error.response?.data);
                if (result.success) {
                    return result.data;
                }
            }

            throw error;
        }
    };

    return {
        mutateAsync: wrapper,
        isPending: mutation.isPending,
    };
}

export function applicationVersionQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.applicationVersion],
        queryFn: async () => {
            const ApplicationVersionView = Result.extend({
                value: z.object({
                    version: z.string(),
                    deployment: z.enum(["Development", "Production", "UnitTest"]),
                    supportedAuthenticationSchemes: z.array(z.enum(["Google", "Development", "Cookies", "UsernamePassword"])),
                }),
            });

            type ApplicationVersionView = z.infer<typeof ApplicationVersionView>;
            const messageKey = MessageKeys.applicationVersion;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ApplicationVersionView.parse(response.data);

            return result;
        },
    });
}

export function useApplicationVersion() {
    return useSuspenseQuery(applicationVersionQueryOptions());
}

export type ApplicationVersionQueryResult = ReturnType<typeof useApplicationVersion>["data"]["value"];

export function allUsersQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.allUsers],
        queryFn: async () => {
            const ArrayOfUserInformation = Result.extend({
                value: z.array(
                    z.object({
                        id: z.string(),
                        name: z.string(),
                        emailAddress: z.string(),
                        emailAddressVerificationStatus: z.enum(["Verified", "Unverified"]),
                        roles: z.array(z.enum(["User", "Trainee", "Admin"])),
                        traineeId: z.string(),
                        hasAdminRole: z.boolean(),
                        hasTraineeRole: z.boolean(),
                        isExternal: z.boolean(),
                    })
                ),
            });

            type ArrayOfUserInformation = z.infer<typeof ArrayOfUserInformation>;
            const messageKey = MessageKeys.allUsers;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ArrayOfUserInformation.parse(response.data);

            return result;
        },
    });
}

export function useAllUsers() {
    return useSuspenseQuery(allUsersQueryOptions());
}

export type AllUsersQueryResult = ReturnType<typeof useAllUsers>["data"]["value"];

export function allTraineesQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.allTrainees],
        queryFn: async () => {
            const AllTraineesQueryModel = Result.extend({
                value: z.object({
                    trainees: z.array(
                        z.object({
                            id: z.string(),
                            userId: z.string(),
                            name: z.string(),
                            emailAddress: z.string(),
                            startDate: z.coerce.date(),
                            proposedSecondment: z.string(),
                            mentors: z.array(z.string()),
                            background: z.string(),
                            mergeRequests: z.array(
                                z.object({
                                    title: z.string(),
                                    webUrl: z.string(),
                                    gitLabId: z.number(),
                                    status: z.enum(["Open", "Closed"]),
                                })
                            ),
                            trackProgresses: z.array(
                                z.object({
                                    trackId: z.string(),
                                    name: z.string(),
                                    startDate: z.coerce.date(),
                                    endDate: z.optional(z.coerce.date()),
                                })
                            ),
                        })
                    ),
                }),
            });

            type AllTraineesQueryModel = z.infer<typeof AllTraineesQueryModel>;
            const messageKey = MessageKeys.allTrainees;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = AllTraineesQueryModel.parse(response.data);

            return result;
        },
    });
}

export function useAllTrainees() {
    return useSuspenseQuery(allTraineesQueryOptions());
}

export type AllTraineesQueryResult = ReturnType<typeof useAllTrainees>["data"]["value"];

const GetTrackProgressForTrainee = z.object({
    userId: z.optional(z.string()),
});
type GetTrackProgressForTrainee = z.infer<typeof GetTrackProgressForTrainee>;

export function getTrackProgressForTraineeQueryOptions(message: GetTrackProgressForTrainee) {
    return queryOptions({
        queryKey: [MessageKeys.getTrackProgressForTrainee, message],
        queryFn: async () => {
            const GetTrackProgressForTraineeQueryModel = Result.extend({
                value: z.object({
                    trackProgresses: z.array(
                        z.object({
                            trackId: z.string(),
                            name: z.string(),
                            slug: z.string(),
                            startDate: z.coerce.date(),
                            endDate: z.optional(z.coerce.date()),
                            status: z.enum(["Completed", "Ended", "Started", "NotStarted"]),
                            moduleProgresses: z.array(
                                z.object({
                                    moduleId: z.string(),
                                    status: z.enum(["Completed", "Ended", "Started", "NotStarted"]),
                                    name: z.string(),
                                    blogPost: z.string(),
                                })
                            ),
                        })
                    ),
                }),
            });

            type GetTrackProgressForTraineeQueryModel = z.infer<typeof GetTrackProgressForTraineeQueryModel>;
            const messageKey = MessageKeys.getTrackProgressForTrainee;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = GetTrackProgressForTraineeQueryModel.parse(response.data);

            return result;
        },
    });
}

export function useGetTrackProgressForTrainee(message: GetTrackProgressForTrainee) {
    return useSuspenseQuery(getTrackProgressForTraineeQueryOptions(message));
}

export type GetTrackProgressForTraineeQueryResult = ReturnType<typeof useGetTrackProgressForTrainee>["data"]["value"];

const EditModule = z.object({
    id: z.string(),
});
type EditModule = z.infer<typeof EditModule>;

export function editModuleQueryOptions(message: EditModule) {
    return queryOptions({
        queryKey: [MessageKeys.editModule, message],
        queryFn: async () => {
            const EditModuleQueryModel = Result.extend({
                value: z.object({
                    module: z.object({
                        id: z.string(),
                        name: z.string(),
                        blogPost: z.string(),
                        defaultVariant: z.enum(["None", "C#", "Java", "Python", "React", "Vue"]),
                        variants: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
                    }),
                    moduleVariants: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
                    blogPosts: z.array(z.string()),
                }),
            });

            type EditModuleQueryModel = z.infer<typeof EditModuleQueryModel>;
            const messageKey = MessageKeys.editModule;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = EditModuleQueryModel.parse(response.data);

            return result;
        },
    });
}

export function useEditModule(message: EditModule) {
    return useSuspenseQuery(editModuleQueryOptions(message));
}

export type EditModuleQueryResult = ReturnType<typeof useEditModule>["data"]["value"];

const EditTrack = z.object({
    id: z.string(),
});
type EditTrack = z.infer<typeof EditTrack>;

export function editTrackQueryOptions(message: EditTrack) {
    return queryOptions({
        queryKey: [MessageKeys.editTrack, message],
        queryFn: async () => {
            const EditTrackQueryModel = Result.extend({
                value: z.object({
                    track: z.object({
                        id: z.string(),
                        name: z.string(),
                        slug: z.string(),
                        modules: z.array(
                            z.object({
                                id: z.string(),
                                name: z.string(),
                                blogPost: z.string(),
                                defaultVariant: z.enum(["None", "C#", "Java", "Python", "React", "Vue"]),
                                variants: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
                            })
                        ),
                    }),
                    modules: z.array(
                        z.object({
                            id: z.string(),
                            name: z.string(),
                            blogPost: z.string(),
                            defaultVariant: z.enum(["None", "C#", "Java", "Python", "React", "Vue"]),
                            variants: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
                        })
                    ),
                }),
            });

            type EditTrackQueryModel = z.infer<typeof EditTrackQueryModel>;
            const messageKey = MessageKeys.editTrack;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = EditTrackQueryModel.parse(response.data);

            return result;
        },
    });
}

export function useEditTrack(message: EditTrack) {
    return useSuspenseQuery(editTrackQueryOptions(message));
}

export type EditTrackQueryResult = ReturnType<typeof useEditTrack>["data"]["value"];

export function getAllModuleVariantsQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.getAllModuleVariants],
        queryFn: async () => {
            const ArrayOfModuleVariant = Result.extend({
                value: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
            });

            type ArrayOfModuleVariant = z.infer<typeof ArrayOfModuleVariant>;
            const messageKey = MessageKeys.getAllModuleVariants;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ArrayOfModuleVariant.parse(response.data);

            return result;
        },
    });
}

export function useGetAllModuleVariants() {
    return useSuspenseQuery(getAllModuleVariantsQueryOptions());
}

export type GetAllModuleVariantsQueryResult = ReturnType<typeof useGetAllModuleVariants>["data"]["value"];

export function trackOverviewQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.trackOverview],
        queryFn: async () => {
            const TrackOverviewQueryModel = Result.extend({
                value: z.object({
                    tracks: z.array(
                        z.object({
                            id: z.string(),
                            name: z.string(),
                            slug: z.string(),
                            modules: z.array(
                                z.object({
                                    id: z.string(),
                                    name: z.string(),
                                    blogPost: z.string(),
                                    defaultVariant: z.enum(["None", "C#", "Java", "Python", "React", "Vue"]),
                                    variants: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
                                })
                            ),
                        })
                    ),
                }),
            });

            type TrackOverviewQueryModel = z.infer<typeof TrackOverviewQueryModel>;
            const messageKey = MessageKeys.trackOverview;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = TrackOverviewQueryModel.parse(response.data);

            return result;
        },
    });
}

export function useTrackOverview() {
    return useSuspenseQuery(trackOverviewQueryOptions());
}

export type TrackOverviewQueryResult = ReturnType<typeof useTrackOverview>["data"]["value"];

const Challenge = z.object({
    authenticationScheme: z.enum(["Google", "Development", "Cookies", "UsernamePassword"]),
    redirectUri: z.string(),
});
type Challenge = z.infer<typeof Challenge>;

export function challengeQueryOptions(message: Challenge) {
    return queryOptions({
        queryKey: [MessageKeys.challenge, message],
        queryFn: async () => {
            const ChallengeResult = Result.extend({
                value: z.object({
                    statusCode: z.number(),
                    redirectUri: z.optional(z.string()),
                }),
            });

            type ChallengeResult = z.infer<typeof ChallengeResult>;
            const messageKey = MessageKeys.challenge;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = ChallengeResult.parse(response.data);

            return result;
        },
    });
}

export function useChallenge(message: Challenge) {
    return useSuspenseQuery(challengeQueryOptions(message));
}

export type ChallengeQueryResult = ReturnType<typeof useChallenge>["data"]["value"];

export function currentUserQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.currentUser],
        queryFn: async () => {
            const CurrentUser = Result.extend({
                value: z.object({
                    name: z.string(),
                    authenticationScheme: z.enum(["Google", "Development", "Cookies", "UsernamePassword"]),
                    roles: z.array(z.enum(["User", "Trainee", "Admin"])),
                }),
            });

            type CurrentUser = z.infer<typeof CurrentUser>;
            const messageKey = MessageKeys.currentUser;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = CurrentUser.parse(response.data);

            return result;
        },
    });
}

export function useCurrentUser() {
    return useSuspenseQuery(currentUserQueryOptions());
}

export type CurrentUserQueryResult = ReturnType<typeof useCurrentUser>["data"]["value"];

const Search = z.object({
    term: z.string(),
    pageNumber: z.number(),
});
type Search = z.infer<typeof Search>;

export function searchQueryOptions(message: Search) {
    return queryOptions({
        queryKey: [MessageKeys.search, message],
        queryFn: async () => {
            const SearchResultOfPostDocument = Result.extend({
                value: z.object({
                    numberOfHits: z.number(),
                    results: z.array(
                        z.object({
                            title: z.string(),
                            plainText: z.string(),
                            markdown: z.string(),
                            slug: z.string(),
                            timburrId: z.string(),
                            index: z.string(),
                        })
                    ),
                }),
            });

            type SearchResultOfPostDocument = z.infer<typeof SearchResultOfPostDocument>;
            const messageKey = MessageKeys.search;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = SearchResultOfPostDocument.parse(response.data);

            return result;
        },
    });
}

export function useSearch(message: Search) {
    return useSuspenseQuery(searchQueryOptions(message));
}

export type SearchQueryResult = ReturnType<typeof useSearch>["data"]["value"];

export function currentStateOfProjectorsQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.currentStateOfProjectors],
        queryFn: async () => {
            const ProjectorState = Result.extend({
                value: z.array(
                    z.object({
                        id: z.string(),
                        name: z.string(),
                        snapshotStrategy: z.enum(["Never", "Always"]),
                        currentSnapshot: z.object({
                            id: z.string(),
                            version: z.number(),
                            sequenceNumber: z.number(),
                            data: z.any(),
                        }),
                        storedSnapshot: z.optional(
                            z.object({
                                id: z.string(),
                                version: z.number(),
                                sequenceNumber: z.number(),
                                data: z.any(),
                            })
                        ),
                    })
                ),
            });

            type ProjectorState = z.infer<typeof ProjectorState>;
            const messageKey = MessageKeys.currentStateOfProjectors;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ProjectorState.parse(response.data);

            return result;
        },
    });
}

export function useCurrentStateOfProjectors() {
    return useSuspenseQuery(currentStateOfProjectorsQueryOptions());
}

export type CurrentStateOfProjectorsQueryResult = ReturnType<typeof useCurrentStateOfProjectors>["data"]["value"];

const CreateUnitTest = z.object({
    aggregateId: z.string(),
});
type CreateUnitTest = z.infer<typeof CreateUnitTest>;

export function createUnitTestQueryOptions(message: CreateUnitTest) {
    return queryOptions({
        queryKey: [MessageKeys.createUnitTest, message],
        queryFn: async () => {
            const String = Result.extend({
                value: z.string(),
            });

            type String = z.infer<typeof String>;
            const messageKey = MessageKeys.createUnitTest;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = String.parse(response.data);

            return result;
        },
    });
}

export function useCreateUnitTest(message: CreateUnitTest) {
    return useSuspenseQuery(createUnitTestQueryOptions(message));
}

export type CreateUnitTestQueryResult = ReturnType<typeof useCreateUnitTest>["data"]["value"];

export function eventLogsQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.eventLogs],
        queryFn: async () => {
            const ArrayOfEventLog = Result.extend({
                value: z.array(
                    z.object({
                        aggregateId: z.string(),
                        sequenceNumber: z.number(),
                        eventMetadata: z.object({
                            timestamp: z.coerce.date(),
                            causedByEmailAddress: z.string(),
                            eventName: z.string(),
                        }),
                        event: z.any(),
                    })
                ),
            });

            type ArrayOfEventLog = z.infer<typeof ArrayOfEventLog>;
            const messageKey = MessageKeys.eventLogs;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ArrayOfEventLog.parse(response.data);

            return result;
        },
    });
}

export function useEventLogs() {
    return useSuspenseQuery(eventLogsQueryOptions());
}

export type EventLogsQueryResult = ReturnType<typeof useEventLogs>["data"]["value"];

const GetBlogPostBySlug = z.object({
    slug: z.string(),
});
type GetBlogPostBySlug = z.infer<typeof GetBlogPostBySlug>;

export function getBlogPostBySlugQueryOptions(message: GetBlogPostBySlug) {
    return queryOptions({
        queryKey: [MessageKeys.getBlogPostBySlug, message],
        queryFn: async () => {
            const PostDocument = Result.extend({
                value: z.object({
                    title: z.string(),
                    plainText: z.string(),
                    markdown: z.string(),
                    slug: z.string(),
                    timburrId: z.string(),
                    index: z.string(),
                }),
            });

            type PostDocument = z.infer<typeof PostDocument>;
            const messageKey = MessageKeys.getBlogPostBySlug;

            const response = await api.post(`/api/messages/${messageKey}`, message);

            const result = PostDocument.parse(response.data);

            return result;
        },
    });
}

export function useGetBlogPostBySlug(message: GetBlogPostBySlug) {
    return useSuspenseQuery(getBlogPostBySlugQueryOptions(message));
}

export type GetBlogPostBySlugQueryResult = ReturnType<typeof useGetBlogPostBySlug>["data"]["value"];

export function retrieveAllBlogPostsQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.retrieveAllBlogPosts],
        queryFn: async () => {
            const ArrayOfPostDocument = Result.extend({
                value: z.array(
                    z.object({
                        title: z.string(),
                        plainText: z.string(),
                        markdown: z.string(),
                        slug: z.string(),
                        timburrId: z.string(),
                        index: z.string(),
                    })
                ),
            });

            type ArrayOfPostDocument = z.infer<typeof ArrayOfPostDocument>;
            const messageKey = MessageKeys.retrieveAllBlogPosts;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ArrayOfPostDocument.parse(response.data);

            return result;
        },
    });
}

export function useRetrieveAllBlogPosts() {
    return useSuspenseQuery(retrieveAllBlogPostsQueryOptions());
}

export type RetrieveAllBlogPostsQueryResult = ReturnType<typeof useRetrieveAllBlogPosts>["data"]["value"];

export function slugsInUseQueryOptions() {
    return queryOptions({
        queryKey: [MessageKeys.slugsInUse],
        queryFn: async () => {
            const ArrayOfString = Result.extend({
                value: z.array(z.string()),
            });

            type ArrayOfString = z.infer<typeof ArrayOfString>;
            const messageKey = MessageKeys.slugsInUse;

            const response = await api.post(`/api/messages/${messageKey}`, {});

            const result = ArrayOfString.parse(response.data);

            return result;
        },
    });
}

export function useSlugsInUse() {
    return useSuspenseQuery(slugsInUseQueryOptions());
}

export type SlugsInUseQueryResult = ReturnType<typeof useSlugsInUse>["data"]["value"];

export function useChangePassword() {
    const messageKey = MessageKeys.changePassword;

    const ChangePassword = z.object({
        currentPassword: z.string(),
        password: z.string(),
        passwordConfirmation: z.string(),
    });
    type ChangePassword = z.infer<typeof ChangePassword>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: ChangePassword) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
    });

    return wrapMutation(mutation);
}

export function useCreateNewUser() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.createNewUser;

    const CreateNewUser = z.object({
        externalId: z.string(),
        name: z.string(),
        emailAddress: z.string(),
        password: z.string(),
        startDate: z.optional(z.coerce.date()),
        proposedSecondment: z.optional(z.string()),
        mentors: z.array(z.string()),
        background: z.optional(z.string()),
    });
    type CreateNewUser = z.infer<typeof CreateNewUser>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: CreateNewUser) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-users"] });
        },
    });

    return wrapMutation(mutation);
}

export function useGrantAdministratorRole() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.grantAdministratorRole;

    const GrantAdministratorRole = z.object({
        id: z.string(),
    });
    type GrantAdministratorRole = z.infer<typeof GrantAdministratorRole>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: GrantAdministratorRole) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-users"] });
        },
    });

    return wrapMutation(mutation);
}

export function useResetPassword() {
    const messageKey = MessageKeys.resetPassword;

    const ResetPassword = z.object({
        id: z.string(),
        password: z.string(),
    });
    type ResetPassword = z.infer<typeof ResetPassword>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: ResetPassword) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
    });

    return wrapMutation(mutation);
}

export function useRevokeAdministratorRole() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.revokeAdministratorRole;

    const RevokeAdministratorRole = z.object({
        id: z.string(),
    });
    type RevokeAdministratorRole = z.infer<typeof RevokeAdministratorRole>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: RevokeAdministratorRole) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-users"] });
        },
    });

    return wrapMutation(mutation);
}

export function useUnverifyEmailAddress() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.unverifyEmailAddress;

    const UnverifyEmailAddress = z.object({
        id: z.string(),
    });
    type UnverifyEmailAddress = z.infer<typeof UnverifyEmailAddress>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: UnverifyEmailAddress) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-users"] });
        },
    });

    return wrapMutation(mutation);
}

export function useVerifyEmailAddress() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.verifyEmailAddress;

    const VerifyEmailAddress = z.object({
        id: z.string(),
        emailAddress: z.string(),
    });
    type VerifyEmailAddress = z.infer<typeof VerifyEmailAddress>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: VerifyEmailAddress) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-users"] });
        },
    });

    return wrapMutation(mutation);
}

export function useAssignTrack() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.assignTrack;

    const AssignTrack = z.object({
        id: z.string(),
        trackId: z.string(),
        startDate: z.coerce.date(),
    });
    type AssignTrack = z.infer<typeof AssignTrack>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: AssignTrack) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-trainees"] });
            queryClient.invalidateQueries({ queryKey: ["get-track-progress-for-trainee"] });
        },
    });

    return wrapMutation(mutation);
}

export function useCompleteModule() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.completeModule;

    const CompleteModule = z.object({
        id: z.string(),
        moduleId: z.string(),
        completionDate: z.coerce.date(),
    });
    type CompleteModule = z.infer<typeof CompleteModule>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: CompleteModule) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["get-track-progress-for-trainee"] });
        },
    });

    return wrapMutation(mutation);
}

export function useEndTrack() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.endTrack;

    const EndTrack = z.object({
        id: z.string(),
        trackId: z.string(),
        endDate: z.coerce.date(),
    });
    type EndTrack = z.infer<typeof EndTrack>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: EndTrack) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-trainees"] });
            queryClient.invalidateQueries({ queryKey: ["get-track-progress-for-trainee"] });
        },
    });

    return wrapMutation(mutation);
}

export function useStartModule() {
    const messageKey = MessageKeys.startModule;

    const StartModule = z.object({
        id: z.string(),
        moduleId: z.string(),
        startDate: z.coerce.date(),
    });
    type StartModule = z.infer<typeof StartModule>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: StartModule) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
    });

    return wrapMutation(mutation);
}

export function useSaveModule() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.saveModule;

    const SaveModule = z.object({
        id: z.string(),
        name: z.string(),
        blogPost: z.string(),
        defaultVariant: z.enum(["None", "C#", "Java", "Python", "React", "Vue"]),
        variants: z.array(z.enum(["None", "C#", "Java", "Python", "React", "Vue"])),
    });
    type SaveModule = z.infer<typeof SaveModule>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: SaveModule) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["edit-module"] });
            queryClient.invalidateQueries({ queryKey: ["edit-track"] });
        },
    });

    return wrapMutation(mutation);
}

export function useSaveTrack() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.saveTrack;

    const SaveTrack = z.object({
        id: z.string(),
        name: z.string(),
        slug: z.string(),
        moduleIds: z.array(z.string()),
    });
    type SaveTrack = z.infer<typeof SaveTrack>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: SaveTrack) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["edit-track"] });
            queryClient.invalidateQueries({ queryKey: ["track-overview"] });
        },
    });

    return wrapMutation(mutation);
}

export function useEnrichUserWithClaims() {
    const messageKey = MessageKeys.enrichUserWithClaims;

    const EnrichUserWithClaims = z.object({
        supportedAuthenticationScheme: z.enum(["Google", "Development", "Cookies", "UsernamePassword"]),
    });
    type EnrichUserWithClaims = z.infer<typeof EnrichUserWithClaims>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: EnrichUserWithClaims) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
    });

    return wrapMutation(mutation);
}

export function useLogin() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.login;

    const Login = z.object({
        authenticationScheme: z.enum(["Google", "Development", "Cookies", "UsernamePassword"]),
        loginAs: z.optional(z.enum(["User", "Trainee", "Admin"])),
        username: z.optional(z.string()),
        password: z.optional(z.string()),
    });
    type Login = z.infer<typeof Login>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: Login) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["current-user"] });
        },
    });

    return wrapMutation(mutation);
}

export function useLogout() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.logout;

    type Logout = void;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: Logout) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["current-user"] });
        },
    });

    return wrapMutation(mutation);
}

export function useMergeRequestSynchronization() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.mergeRequestSynchronization;

    type MergeRequestSynchronization = void;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: MergeRequestSynchronization) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-trainees"] });
        },
    });

    return wrapMutation(mutation);
}

export function useAddBlogPost() {
    const messageKey = MessageKeys.addBlogPost;

    const AddBlogPost = z.object({
        title: z.string(),
        slug: z.string(),
        markdown: z.string(),
    });
    type AddBlogPost = z.infer<typeof AddBlogPost>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: AddBlogPost) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
    });

    return wrapMutation(mutation);
}

export function useUpdateBlogPost() {
    const messageKey = MessageKeys.updateBlogPost;

    const UpdateBlogPost = z.object({
        id: z.string(),
        title: z.string(),
        markdown: z.string(),
    });
    type UpdateBlogPost = z.infer<typeof UpdateBlogPost>;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: UpdateBlogPost) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
    });

    return wrapMutation(mutation);
}

export function useImportFromAurorus() {
    const queryClient = useQueryClient();
    const messageKey = MessageKeys.importFromAurorus;

    type ImportFromAurorus = void;

    const mutation = useMutation({
        mutationKey: [messageKey],
        mutationFn: async (message: ImportFromAurorus) => {
            const response = await api.post(`/api/messages/${messageKey}`, (message as unknown) ?? {});
            const result = Result.parse(response.data);

            if (result.isSuccess) {
                return result;
            }

            throw result;
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ["all-users"] });
            queryClient.invalidateQueries({ queryKey: ["all-trainees"] });
        },
    });

    return wrapMutation(mutation);
}

// Generation took 00:00:00.0282806
