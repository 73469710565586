import { useUserViewModel } from "@/modules/admin/user_administration/viewModels/useUserViewModel";
import { AllUsersQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import styled from "@emotion/styled";
import SchoolIcon from "@mui/icons-material/School";
import { IconButton, Switch, TableCell, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import LockResetIcon from "@mui/icons-material/LockReset";
import { ResetPasswordDialog } from "@/modules/admin/user_administration/ResetPasswordDialog";

const StyledTableRow = styled(TableRow)(() => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

type User = ArrayElement<AllUsersQueryResult>;

type Props = {
    user: User;
};

export const User = (props: Props) => {
    const { user } = props;
    const viewModel = useUserViewModel(user);
    const [emailAddress, setEmailAddress] = useState(user.emailAddress);
    const [openPasswordReset, setOpenPasswordReset] = useState(false);

    const openPasswordResetDialog = () => {
        setOpenPasswordReset(true);
    };

    const closePasswordResetDialog = () => {
        setOpenPasswordReset(false);
    };

    return (
        <StyledTableRow key={user.emailAddress}>
            <TableCell sx={{ whiteSpace: "nowrap" }}>{user.name}</TableCell>
            <TableCell>
                <TextField
                    sx={{ width: "100%" }}
                    disabled={user.isExternal || user.emailAddressVerificationStatus === "Verified"}
                    defaultValue={user.emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                />
            </TableCell>

            <TableCell>
                <Switch
                    checked={user.isExternal || user.emailAddressVerificationStatus === "Verified"}
                    disabled={user.isExternal}
                    onChange={async (e) => {
                        if (e.target.checked) {
                            await viewModel.verifyAsync(emailAddress);
                        } else {
                            await viewModel.unverifyAsync();
                        }
                    }}
                />
            </TableCell>

            <TableCell>
                <Switch
                    checked={user.hasAdminRole}
                    onChange={async (e) => {
                        if (e.target.checked) {
                            await viewModel.grantAdminRoleAsync();
                        } else {
                            await viewModel.revokeAdminRoleAsync();
                        }
                    }}
                />
            </TableCell>
            <TableCell>{user.hasTraineeRole && <SchoolIcon color="primary" fontSize="large" />}</TableCell>
            <TableCell>
                {user.isExternal && (
                    <>
                        <ResetPasswordDialog user={user} open={openPasswordReset} onClose={() => closePasswordResetDialog()} />
                        <IconButton onClick={() => openPasswordResetDialog()}>
                            <LockResetIcon color="secondary" />
                        </IconButton>
                    </>
                )}
            </TableCell>
        </StyledTableRow>
    );
};
