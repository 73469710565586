import dayjs from "dayjs";

export function formatDate(date?: Date) {
    return dayjs(date).format("DD-MM-YYYY");
}

export function formatDateTime(date?: Date) {
    return dayjs(date).format("DD-MM-YYYY HH:mm:ssZ");
}

export function parseDate(date?: string) {
    return dayjs(date).toDate();
}
