import { visit } from "unist-util-visit";

import type { Root } from "mdast";
import type { Plugin } from "unified";

export const directivesPlugin: Plugin<[], Root> = () => {
    return (tree: Root): undefined => {
        visit(tree, function (node) {
            if (node.type === "containerDirective" || node.type === "leafDirective" || node.type === "textDirective") {
                const data = node.data ?? (node.data = {});
                const attributes = node.attributes ?? {};

                switch (node.name) {
                    case "blog_post":
                        data.hName = "BlogPost";
                        data.hProperties = { slug: attributes["slug"] };

                        break;
                    case "youtube":
                        data.hName = "YouTube";
                        data.hProperties = {
                            vid: attributes["vid"],
                        };

                        break;

                    case "info":
                    case "note":
                    case "tip":
                        data.hName = "Alert";
                        data.hProperties = { severity: "info", variant: "outlined" };

                        break;
                    case "danger":
                        data.hName = "Alert";
                        data.hProperties = { severity: "error", variant: "outlined" };

                        break;
                    case "caution":
                        data.hName = "Alert";
                        data.hProperties = { severity: "warning", variant: "outlined" };

                        break;
                }
            }
        });
    };
};
