import { useTraineesViewModel } from "@/modules/admin/trainee_overview/viewModels/useTraineesViewModel";
import { ArrayElement } from "@/types/arrayElement";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { LoadingButton } from "@mui/lab";
import { Container, ListItemButton } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export function Trainees() {
    const viewModel = useTraineesViewModel();
    const navigate = useNavigate();

    type Trainee = ArrayElement<typeof viewModel.trainees>;

    const columns: GridColDef<Trainee>[] = [
        {
            field: "startDate",
            headerName: "Start Date / Cohort",
            valueGetter: (_value, row) => row.startDate,
            valueFormatter: (value) =>
                new Date(value).toLocaleString("nl-NL", {
                    month: "long",
                    year: "numeric",
                }),
            type: "dateTime",
            flex: 2,
        },
        {
            field: "name",
            headerName: "Name",
            type: "string",
            flex: 2,
            renderCell: (params) => {
                return (
                    <ListItemButton
                        onClick={() => {
                            navigate(params.row.id);
                        }}>
                        {params.row.name}
                    </ListItemButton>
                );
            },
        },
        {
            field: "mentors",
            headerName: "Mentors",
            valueFormatter: (value: string[]) => value.join(", "),
            type: "string",
            flex: 2,
            sortable: false,
        },
        {
            field: "background",
            headerName: "Background",
            type: "string",
            flex: 2,
        },
        {
            field: "proposedSecondment",
            headerName: "Proposed Secondment",
            type: "string",
            flex: 2,
            sortable: false,
        },
        {
            field: "track",
            headerName: "Active Track",
            type: "string",
            valueGetter: (_value, row) =>
                row.trackProgresses.findLast(
                    (track) =>
                        dayjs(track.startDate).isBefore(dayjs()) &&
                        (track.endDate === undefined || dayjs(track.endDate).isAfter(dayjs()))
                )?.name ?? "None",
            flex: 2,
        },
        {
            field: "mergeRequests",
            headerName: "Open Merge Requests",
            type: "number",
            valueGetter: (_value, row) => {
                return row.mergeRequests?.filter((mr) => mr.status === "Open").length;
            },
            flex: 1,
            sortable: true,
        },
    ];
    return (
        <>
            <LoadingButton
                data-cy="GitlabButton"
                variant="contained"
                startIcon={<CloudSyncIcon />}
                loading={viewModel.isPending}
                onClick={() => viewModel.synchronizeMergeRequests()}>
                Synchronize with GitLab
            </LoadingButton>
            <br />
            <Container maxWidth={false}>
                {viewModel.trainees && (
                    <DataGrid
                        data-cy="DataGridTrainee"
                        rows={viewModel.trainees}
                        columns={columns}
                        getRowId={(row) => row.id}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    {
                                        field: "startDate",
                                        sort: "asc",
                                    },
                                ],
                            },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}></DataGrid>
                )}
            </Container>
        </>
    );
}
