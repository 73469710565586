import { GetTrackProgressForTraineeQueryResult, useGetTrackProgressForTrainee } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { useNavigate } from "react-router-dom";

type Track = ArrayElement<GetTrackProgressForTraineeQueryResult["trackProgresses"]>;
type Module = ArrayElement<Track["moduleProgresses"]>;

export const useTrackViewModel = (slug: string, moduleBlogPost?: string) => {
    const navigate = useNavigate();
    const tracks = useGetTrackProgressForTrainee({ userId: undefined });

    const track =
        tracks.data.value.trackProgresses.find((track) => track.slug === slug) ??
        tracks.data.value.trackProgresses.find((track) => track.name === slug);

    if (!track) {
        return 404;
    }

    const currentModule = track.moduleProgresses.find((m) => m.blogPost === moduleBlogPost);
    const post = currentModule?.blogPost ?? moduleBlogPost ?? track.moduleProgresses[0].blogPost;
    const moduleIndex = track.moduleProgresses.findIndex((moduleProgress) => moduleProgress.blogPost === post);

    return {
        track: track.name,
        modules: track.moduleProgresses,
        post: post,
        moduleIndex: moduleIndex,
        navigateToModule: (module: Module) => {
            navigate(`/academy/tracks/${slug}/${module.blogPost}`);
        },
    };
};
