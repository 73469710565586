import { useCreateBlogPostViewModel } from "@/modules/admin/blog_posts/viewModels/useCreateBlogPostViewModel";
import { BlogPostEditor } from "@/modules/admin/blog_posts/BlogPostEditor";

export function CreateBlogPost() {
    const viewModel = useCreateBlogPostViewModel();

    return (
        <BlogPostEditor
            blogPost={{
                slug: "",
                markdown: "",
                title: "",
            }}
            onSaveClick={(blogPost) => viewModel.saveAsync(blogPost.title, blogPost.slug, blogPost.markdown)}
        />
    );
}
