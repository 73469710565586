import { ScrollToTop } from "@/modules/common/components/ScrollToTop";
import { MainMenu, MainMenuProps } from "@/modules/common/navigation/MainMenu";
import { Submenu } from "@/modules/common/navigation/Submenu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppBar, Container, Fab, useScrollTrigger, useTheme } from "@mui/material";
import { cloneElement, useState } from "react";

type MainMenuOptions = MainMenuProps["activeMenu"];

type Props = {
    activeMenu: MainMenuOptions;
    children: React.ReactElement;
};

function ElevationScroll(props: Props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export function ElevateAppBar(props: Readonly<Props>) {
    const { activeMenu, children } = props;
    const [submenu, setSubmenu] = useState<MainMenuOptions>(activeMenu);
    const theme = useTheme();

    const toolbar = theme.mixins.toolbar;
    const minHeight = toolbar.minHeight!;
    const height = Number(minHeight) * (activeMenu === "/" ? 1 : 2);

    return (
        <>
            <ElevationScroll {...props}>
                <AppBar
                    data-cy="header"
                    onMouseLeave={() => setSubmenu(activeMenu)}
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}>
                    <MainMenu data-cy="activemenu" activeMenu={activeMenu} setSubmenu={setSubmenu} />
                    <Submenu data-cy="submenu" activeMenu={submenu} />
                </AppBar>
            </ElevationScroll>
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    height: `calc(100vh - ${height}px - 2em)`,
                    position: "relative",
                    top: `calc(${height}px + 2em)`,
                    width: "100%",
                }}>
                {children}

                <ScrollToTop>
                    <Fab size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon />
                    </Fab>
                </ScrollToTop>
            </Container>
        </>
    );
}
