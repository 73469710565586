/* eslint-disable react/prop-types */
import "@mdxeditor/editor/style.css";

import { BlogPostEditor } from "@/modules/admin/blog_posts/BlogPostEditor";
import { useEditBlogPostViewModel } from "@/modules/admin/blog_posts/viewModels/useEditBlogPostViewModel";
import { useParams } from "react-router-dom";

export function EditBlogPost() {
    const params = useParams();
    const blogPost = params.blog_post!;

    const viewModel = useEditBlogPostViewModel(blogPost);

    return (
        <BlogPostEditor
            key={viewModel.blogPost.slug}
            blogPost={viewModel.blogPost}
            onSaveClick={(blogPost) => viewModel.saveAsync(viewModel.blogPost.timburrId, blogPost.title, blogPost.markdown)}
        />
    );
}
