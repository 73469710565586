import { useUserViewModel } from "@/modules/admin/user_administration/viewModels/useUserViewModel";
import { AllUsersQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";

type User = ArrayElement<AllUsersQueryResult>;

type Props = {
    open: boolean;
    onClose: () => void;
    user: User;
};

export function ResetPasswordDialog(props: Readonly<Props>) {
    const { open, onClose, user } = props;
    const viewModel = useUserViewModel(user);
    const [password, setPassword] = useState("");
    const [validationMessages, setValidationMessages] = useState<string[]>([]);

    async function resetPassword() {
        const result = await viewModel.resetPasswordAsync(password);
        setValidationMessages(result.errors.map((error) => error.message));

        if (result.isSuccess) {
            close();
        }
    }

    function close() {
        setPassword("");
        onClose();
    }

    return (
        <Dialog onClose={() => close()} open={open}>
            <DialogTitle>Reset password for {user.emailAddress}</DialogTitle>
            <DialogContent>
                {validationMessages.length > 0 && (
                    <Alert severity="error">
                        <ul>
                            {validationMessages.map((message) => (
                                <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                <DialogContentText>Enter a new password for {user.emailAddress}</DialogContentText>
                <TextField
                    autoFocus
                    required
                    error={validationMessages.length > 0}
                    margin="dense"
                    id="password"
                    name="password"
                    label="New password"
                    autoComplete="new-password"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => close()}>
                    Cancel
                </Button>
                <Button color="warning" onClick={() => resetPassword()}>
                    Reset password
                </Button>
            </DialogActions>
        </Dialog>
    );
}
