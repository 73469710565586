import { Navigate, Outlet } from "react-router-dom";

import { useAuthenticationContext } from "@/modules/common/auth/useAuthenticationContext";
import { Suspender } from "@/modules/common/components/Suspender";

export function AdminLayout() {
    const authenticationContext = useAuthenticationContext();

    return authenticationContext.isAdministrator ? (
        <Suspender>
            <Outlet />
        </Suspender>
    ) : (
        <Navigate to="/" />
    );
}
