import { AuthenticationContext } from "@/modules/common/auth/authenticationContext";
import { useContext } from "react";

export const useAuthenticationContext = () => {
    const context = useContext(AuthenticationContext);

    if (!context) {
        throw new Error("useAuthenticationContext must be used within a AuthenticationContextProvider");
    }

    return context;
};
