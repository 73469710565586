import React from "react";

import { Box, Fade } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

export const ScrollToTop = (props: React.PropsWithChildren) => {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                sx={{ position: "fixed", bottom: 16, right: 16, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                {children}
            </Box>
        </Fade>
    );
};
