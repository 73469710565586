import { RenderedChart } from "@/modules/admin/trainee_overview/RadarChartApex";

export function Competencies() {
    return (
        <div style={{ flex: 1, display: "flex", width: "30%" }}>
            <h1>Competencies</h1>
            <RenderedChart />
        </div>
    );
}
