import { Link } from "@/modules/academy/Link";
import { AllTraineesQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

type Trainee = ArrayElement<AllTraineesQueryResult["trainees"]>;
type MergeRequest = ArrayElement<Trainee["mergeRequests"]>;

type MergeRequestsOverviewProps = {
    mergeRequests: MergeRequest[];
};

export function MergeRequestsOverview(props: Readonly<MergeRequestsOverviewProps>) {
    const { mergeRequests } = props;

    const columns: GridColDef<MergeRequest>[] = [
        {
            field: "title",
            headerName: "Title",
            flex: 2,
        },
        {
            field: "username",
            headerName: "GitLab Username",
            flex: 2,
        },
        {
            field: "gitLabId",
            headerName: "GitLab ID",
            flex: 1,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
        },
        {
            field: "webUrl",
            headerName: "URL",
            flex: 1,
            renderCell: (params) => <Link href={params.value}>GitLab Link</Link>,
        },
    ];

    return (
        <DataGrid
            rows={mergeRequests}
            columns={columns}
            getRowId={(row) => row.gitLabId}
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: "status",
                            sort: "desc",
                        },
                    ],
                },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}></DataGrid>
    );
}
