import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import ReactTopBarProgress from "react-topbar-progress-indicator";

export function TopBarProgress() {
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const isLoading = isFetching > 0 || isMutating > 0;

    if (isLoading) {
        return <ReactTopBarProgress />;
    } else {
        return null;
    }
}
