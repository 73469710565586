import { DialogButton, insertDirective$, usePublisher } from "@mdxeditor/editor";

export const YouTubeButton = () => {
    // grab the insertDirective action (a.k.a. publisher) from the
    // state management system of the directivesPlugin
    const insertDirective = usePublisher(insertDirective$);

    return (
        <DialogButton
            tooltipTitle="Insert Youtube video"
            submitButtonTitle="Insert video"
            dialogInputPlaceholder="Paste the youtube video URL"
            buttonContent="YT"
            onSubmit={(url) => {
                const videoId = new URL(url).searchParams.get("v");
                if (videoId) {
                    insertDirective({
                        name: "youtube",
                        type: "leafDirective",
                        attributes: { id: videoId },
                    });
                } else {
                    alert("Invalid YouTube URL");
                }
            }}
        />
    );
};
