/* eslint-disable react/prop-types */

import { LeafDirective } from "mdast-util-directive";
import { DirectiveDescriptor } from "@mdxeditor/editor";
import { BlogPostWrapper } from "@/modules/academy/BlogPostWrapper";
import { ArgumentTypes } from "@/types/argumentTypes";
import { Button, Card, CardActions, CardContent, CardHeader, Skeleton } from "@mui/material";

type BlogPostProps = ArgumentTypes<typeof BlogPostWrapper>[0];

interface BlogPostDirectiveNode extends LeafDirective {
    name: "blogPost";
    attributes: BlogPostProps;
}

export const BlogPostDirectiveDescriptor: DirectiveDescriptor<BlogPostDirectiveNode> = {
    name: "blogPost",
    type: "leafDirective",
    testNode(node) {
        return node.name === "blogPost";
    },
    attributes: ["category", "post"],
    hasChildren: false,
    Editor: ({ mdastNode, lexicalNode, parentEditor }) => {
        return (
            <Card>
                <CardHeader title={`blog_posts/${mdastNode.attributes.slug}`}></CardHeader>
                <CardContent>
                    <Skeleton animation={false} width={210} height={210} />
                </CardContent>
                <CardActions>
                    <Button
                        color="warning"
                        onClick={() => {
                            parentEditor.update(() => {
                                lexicalNode.selectNext();
                                lexicalNode.remove();
                            });
                        }}>
                        Delete
                    </Button>
                </CardActions>
            </Card>
        );
    },
};
