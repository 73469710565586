import { Link } from "@/modules/academy/Link";
import { directivesPlugin } from "@/modules/academy/mdx_directives/directivesPlugin";
import { LoadingIndicator } from "@/modules/common/components/LoadingIndicator";
import { Suspender } from "@/modules/common/components/Suspender";
import loadable from "@loadable/component";
import { EvaluateOptions, evaluateSync } from "@mdx-js/mdx";
import { Alert, Slide, Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";
import { MDXComponents } from "mdx/types";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import * as runtime from "react/jsx-runtime";
import rehypePrism from "rehype-prism-plus";
import rehypeShiftHeading from "rehype-shift-heading";
import rehypeSlug from "rehype-slug";
import remarkDirective from "remark-directive";
import remarkGfm from "remark-gfm";
import { Image } from "./Image";
import { useGetBlogPostBySlug } from "@/services/message-service";

const Deck = loadable(() => import("@/modules/common/presentations/Deck"), {
    resolveComponent: (components) => components.Deck,
    fallback: <LoadingIndicator />,
});
const Mermaid = loadable(() => import("@/modules/common/components/Mermaid"), {
    resolveComponent: (components) => components.Mermaid,
    fallback: <LoadingIndicator />,
});
const YouTube = loadable(() => import("@/modules/common/components/YouTube"), {
    resolveComponent: (components) => components.YouTube,
    fallback: <LoadingIndicator />,
});

const Header1 = (props: TypographyProps) => <Typography data-cy="h1" variant="h1" {...props} />;
const Header2 = (props: TypographyProps) => <Typography data-cy="h2" variant="h2" {...props} />;
const Header3 = (props: TypographyProps) => <Typography data-cy="h3" variant="h3" {...props} />;
const Header4 = (props: TypographyProps) => <Typography data-cy="h4" variant="h4" {...props} />;
const Header5 = (props: TypographyProps) => <Typography data-cy="h5" variant="h5" {...props} />;
const Header6 = (props: TypographyProps) => <Typography data-cy="h6" variant="h6" {...props} />;
const Body = (props: TypographyProps) => <Typography data-cy="body1" variant="body1" {...props} />;

type BlogPostProps = {
    slug: string;
};
function BlogPost(props: BlogPostProps) {
    const { slug } = props;
    const code = useGetBlogPostBySlug({ slug: slug });

    const Content = useMemo(() => {
        const options = {
            ...runtime,
            rehypePlugins: [[rehypePrism, { showLineNumbers: true }], rehypeSlug, [rehypeShiftHeading, { shift: 1 }]],
            remarkPlugins: [remarkDirective, directivesPlugin, remarkGfm],
        } as unknown as EvaluateOptions;

        const { default: MDXContent } = evaluateSync(code.data.value.markdown, options);

        return MDXContent;
    }, [code.data.value.markdown]);

    const components = {
        a: Link,
        h1: Header1,
        h2: Header2,
        h3: Header3,
        h4: Header4,
        h5: Header5,
        h6: Header6,
        p: Body,
        img: Image,
        Mermaid: Mermaid,
        Deck: Deck,
        Slide: Slide,
        BlogPost: BlogPostWrapper,
        YouTube: YouTube,
        Alert: Alert,
    } as unknown as MDXComponents;

    return Content && <Content components={components} />;
}

type Props = {
    slug?: string;
};
export function BlogPostWrapper(props: Readonly<Props>) {
    const { slug } = props;
    const { pathname } = useLocation();

    return (
        <Suspender>
            <BlogPost slug={slug ?? pathname.split("/").slice(-1)[0]} />
        </Suspender>
    );
}
