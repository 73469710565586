import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { EventLogsQueryResult, useCreateUnitTest, useEventLogs } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { useState } from "react";
import { formatDateTime } from "@/formatter";

type Event = ArrayElement<EventLogsQueryResult>;

type AggregateHistoryDialogProps = {
    aggregate: string;
    onClose: () => void;
    onSelected: (event: Event) => void;
};
export function AggregateHistoryDialog(props: Readonly<AggregateHistoryDialogProps>) {
    const { aggregate, onClose, onSelected } = props;

    const eventLogs = useEventLogs();
    const unitTest = useCreateUnitTest({ aggregateId: aggregate });
    const [expanded, setExpanded] = useState<string | false>("timeline");

    const events = eventLogs.data.value.filter((event) => event.aggregateId === aggregate);

    const handleChange = (panel: string, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Dialog open={true} onClose={() => onClose()} scroll="paper">
            <DialogTitle>History of {aggregate}</DialogTitle>
            <DialogContent dividers>
                <Accordion expanded={expanded === "timeline"} onChange={(_, isExpanded) => handleChange("timeline", isExpanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Timeline</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stepper orientation="vertical">
                            {events.map((event) => (
                                <Step
                                    key={event.sequenceNumber}
                                    completed
                                    active
                                    onClick={() => onSelected(event)}
                                    sx={{
                                        cursor: "pointer",
                                    }}>
                                    <StepLabel>
                                        <Typography variant="subtitle1">
                                            {event.sequenceNumber}: {event.eventMetadata.eventName}
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{formatDateTime(event.eventMetadata.timestamp)}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "unittest"} onChange={(_, isExpanded) => handleChange("unittest", isExpanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Unit test</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            overflow: "auto",
                        }}>
                        <IconButton onClick={() => unitTest.data && navigator.clipboard.writeText(unitTest.data.value)}>
                            <ContentCopyIcon />
                            <Typography variant="button">Copy to clipboard</Typography>
                        </IconButton>
                        <Typography component="pre">{unitTest.data.value}</Typography>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
        </Dialog>
    );
}
