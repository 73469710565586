import { useAllUsers, useCreateNewUser, useImportFromAurorus } from "@/services/message-service";
import { ArgumentTypes } from "@/types/argumentTypes";
import { ArrayElement } from "@/types/arrayElement";

type MutateAsync = ReturnType<typeof useCreateNewUser>["mutateAsync"];
type MutateArguments = ArgumentTypes<MutateAsync>;
type User = ArrayElement<MutateArguments>;

export const useUsersViewModel = () => {
    const users = useAllUsers();
    const importFromAurorus = useImportFromAurorus();
    const createNewUser = useCreateNewUser();

    return {
        users: users.data.value.toSorted((a, b) => a.name.localeCompare(b.name)),
        isImporting: importFromAurorus.isPending,
        importFromAurorus: () => importFromAurorus.mutateAsync(),
        createNewUserAsync: async (user: User) => await createNewUser.mutateAsync(user),
    };
};
