import { Projector } from "@/modules/admin/projectors/Projector";
import { useProjectorOverviewViewModel } from "@/modules/admin/projectors/viewModels/useProjectorOverviewViewModel";
import { Masonry } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";

export function ProjectorOverview() {
    const viewModel = useProjectorOverviewViewModel();

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [snapshot, setSnapshot] = useState("");

    function showSnapshot<TSnapshot>(title: string, snapshot: TSnapshot) {
        setTitle(title);
        setSnapshot(JSON.stringify(snapshot, null, "  "));
        setOpen(true);
    }

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} scroll="paper">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>
                    <Typography component="pre">{snapshot}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <Masonry columns={6} spacing={5}>
                {viewModel.projectors.map((projector) => (
                    <Projector
                        key={projector.id}
                        projector={projector}
                        showSnapshot={(title, snapshot) => showSnapshot(title, snapshot)}
                        isUpToDate={projector.currentSnapshot.sequenceNumber === viewModel.lastSequenceNumber}
                    />
                ))}
            </Masonry>
        </>
    );
}
