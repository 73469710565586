import { DraggableModule } from "@/modules/admin/tracks/DraggableModule";
import { StrictModeDroppable } from "@/modules/common/dnd/StrictModeDroppable";
import { EditTrackQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

type Module = ArrayElement<EditTrackQueryResult["modules"]>;

type Props = PropsWithChildren & {
    id: string;
    modules: Module[];
    sx?: SxProps<Theme>;
};

export function DroppableModules(props: Props) {
    const { id, modules, sx, children } = props;

    return (
        <StrictModeDroppable droppableId={id}>
            {(provided) => (
                <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={[
                        (theme) => ({
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: "50%",
                            padding: theme.spacing(1),
                            borderWidth: "1",
                            borderStyle: "dashed",
                            borderColor: theme.palette.secondary.main,
                        }),
                        ...(Array.isArray(sx) ? sx : [sx]),
                    ]}>
                    {modules.map((item, index) => (
                        <DraggableModule key={item.name} module={item} index={index} />
                    ))}
                    {provided.placeholder}
                    {children}
                </Box>
            )}
        </StrictModeDroppable>
    );
}
