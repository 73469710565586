import { formatDateTime } from "@/formatter";
import { AggregateHistoryDialog } from "@/modules/admin/events/AggregateHistoryDialog";
import { EventDetailsDialog } from "@/modules/admin/events/EventDetailsDialog";
import { useEventsViewModel } from "@/modules/admin/events/viewModels/useEventsViewModel";
import { Suspender } from "@/modules/common/components/Suspender";
import { ArrayElement } from "@/types/arrayElement";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button, Container } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridToolbar } from "@mui/x-data-grid";

export function Events() {
    const viewModel = useEventsViewModel();
    type EventLog = ArrayElement<typeof viewModel.events>;

    const columns: GridColDef<EventLog>[] = [
        { field: "sequenceNumber", headerName: "Sequence number", type: "number", flex: 1 },
        {
            field: "aggregateId",
            headerName: "Aggregate",
            type: "string",
            flex: 1,
            sortable: false,
            renderCell(params) {
                return (
                    <Button onClick={() => viewModel.selectAggregate(params.row.aggregateId)}>{params.row.aggregateId} </Button>
                );
            },
        },
        {
            field: "causedBy",
            headerName: "Caused by",
            valueGetter: (_value, row) => row.eventMetadata.causedByEmailAddress,
            type: "string",
            flex: 1,
            sortable: false,
        },
        {
            field: "timestamp",
            headerName: "Timestamp",
            valueGetter: (_value, row) => row.eventMetadata.timestamp,
            valueFormatter: (value) => formatDateTime(value),
            type: "dateTime",
            flex: 1,
        },
        {
            field: "eventType",
            headerName: "Event type",
            valueGetter: (_value, row) => row.eventMetadata.eventName,
            type: "string",
            flex: 1,
            sortable: false,
        },
        {
            field: "event",
            valueGetter: (_value, row) => {
                return JSON.stringify(row.event);
            },
            type: "string",
            flex: 1,
            sortable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Event",
            getActions: (params) => [
                <GridActionsCellItem
                    key={params.row.sequenceNumber}
                    icon={<DescriptionIcon color="primary" />}
                    onClick={() => viewModel.selectEvent(params.row)}
                    label="Show event"
                />,
            ],
            flex: 1,
        },
    ];

    return (
        <>
            {viewModel.selectedAggregate && (
                <Suspender>
                    <AggregateHistoryDialog
                        aggregate={viewModel.selectedAggregate}
                        onClose={() => viewModel.deselectAggregate()}
                        onSelected={(event) => viewModel.selectEvent(event)}
                    />
                </Suspender>
            )}

            {viewModel.selectedEvent && (
                <EventDetailsDialog selectedEvent={viewModel.selectedEvent} onClose={() => viewModel.deselectEvent()} />
            )}

            <Container maxWidth={false}>
                <DataGrid
                    data-cy="EventLogTable"
                    rows={viewModel.events}
                    columns={columns}
                    columnVisibilityModel={{
                        event: false,
                    }}
                    disableColumnSelector
                    rowSelection={false}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: "sequenceNumber",
                                    sort: "desc",
                                },
                            ],
                        },
                    }}
                    getRowId={(row) => row.sequenceNumber}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Container>
        </>
    );
}
