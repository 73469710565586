import ApexCharts from "apexcharts";
import { useEffect } from "react";

const options = {
    chart: {
        type: "radar",
        sparkline: {
            enabled: true,
        },
        responsive: [
            {
                breakpoint: 400,
                options: {},
            },
        ],
    },
    series: [
        {
            name: "03 May 2021",
            data: [40, 20, 30, 20, 50, 20, 25, 20, 34, 20, 20, 15, 20, 60, 80, 20],
        },
        {
            name: "01 August 2021",
            data: [40, 30, 60, 70, 80, 40, 30, 20, 20, 70, 90, 100, 30, 15, 25, 40],
        },
    ],
    labels: [
        "Object Orientation",
        "Functional Programming",
        "Test-Driven Development & Behavior-Driven Development",
        "Architectural Styles & Advanced Design Principles (SOLID, Design Patterns, ...)",
        "Domain-Driven Design Principles & Patterns (Bounded Context)",
        "Event Storming",
        "CQRS, Event Sourcing, Projections, Enterprise/Domain Integration",
        "Software Development Lifecycle (Agile, Kanban, ...)",
        "SCM (Version Control/Branching/...)",
        "Packaging & Dependencies",
        "Build & Deploy (CI/CD, Pipelines, Containerization)",
        "Logging, Monitoring, & Analysis",
        "Code organization (Classes, Functions, Packages, Namespaces)",
        "Type System",
        "Branching (Loops & Conditioning)",
        "Dealing with Exceptions & Errors",
    ],
    fill: {
        opacity: 0.5,
    },
    stroke: {
        show: true,
        width: 2,
        colors: [],
        dashArray: 0,
    },
    markers: {
        size: 5,
        hover: {
            size: 10,
        },
    },
    legend: {
        show: true,
        position: "right",
    },
    theme: {
        mode: "light",
        palette: "palette1",
        monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
        },
    },
};

export const RenderedChart = () => {
    useEffect(() => {
        const chart = new ApexCharts(document.querySelector("div#chart"), options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <div id="chart" style={{ width: "100%", height: "100%", marginTop: 20, marginBottom: 10 }}>
            <canvas></canvas>
        </div>
    );
};
