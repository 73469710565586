import { ElevateAppBar } from "@/modules/common/ElevateAppBar";
import { Suspender } from "@/modules/common/components/Suspender";
import { TopBarProgress } from "@/modules/common/components/TopBarProgress";
import { MainMenuProps } from "@/modules/common/navigation/MainMenu";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

type MainMenuOptions = MainMenuProps["activeMenu"];

const useActiveMenu = (): MainMenuOptions => {
    const location = useLocation();

    if (location.pathname.startsWith("/academy")) {
        return "/academy";
    }

    if (location.pathname.startsWith("/admin")) {
        return "/admin";
    }

    if (location.pathname.startsWith("/info")) {
        return "/info";
    }

    if (location.pathname.startsWith("/mentoring")) {
        return "/mentoring";
    }

    return "/";
};

export function CommonLayout() {
    const location = useLocation();
    const activeMenu = useActiveMenu();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <TopBarProgress />

            <ElevateAppBar activeMenu={activeMenu}>
                <>
                    <span id="back-to-top-anchor"></span>
                    <Suspender>
                        <Outlet />
                    </Suspender>
                </>
            </ElevateAppBar>
        </>
    );
}
