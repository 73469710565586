import { Suspender } from "@/modules/common/components/Suspender";
import { MainMenuProps } from "@/modules/common/navigation/MainMenu";
import { SubmenuItem } from "@/modules/common/navigation/SubmenuItem";
import { useGetTrackProgressForTrainee } from "@/services/message-service";
import { Collapse, List, ListItem, Toolbar } from "@mui/material";
import dayjs from "dayjs";

type MainMenuOptions = MainMenuProps["activeMenu"];

type SubmenuProps = {
    activeMenu: MainMenuOptions;
};

function TrackSubmenuItems() {
    const tracks = useGetTrackProgressForTrainee({ userId: undefined });

    return tracks.data.value.trackProgresses
        .filter((track) => dayjs(track.startDate).isBefore(dayjs()))
        .map((track) => <SubmenuItem key={track.slug} text={track.name} url={`/academy/tracks/${encodeURI(track.slug)}`} />);
}

export function Submenu(props: Readonly<SubmenuProps>) {
    const { activeMenu } = props;

    return (
        <Collapse
            in={activeMenu === "/academy" || activeMenu === "/info" || activeMenu === "/mentoring" || activeMenu === "/admin"}
            orientation="vertical">
            <Toolbar
                data-cy="toolbar"
                variant="dense"
                sx={{
                    backgroundColor: "secondary.main",
                    justifyContent: "center",
                }}>
                <List dense>
                    <ListItem alignItems="center" dense disablePadding>
                        {activeMenu === "/academy" && (
                            <Suspender>
                                <TrackSubmenuItems data-cy="academy" />
                            </Suspender>
                        )}

                        {activeMenu === "/info" && (
                            <>
                                <SubmenuItem url="/info/sogyo_sites" text="Sogyo Sites" data-cy="sogyosites" />
                                <SubmenuItem url="/info/library" text="Library" />
                                <SubmenuItem url="/info/industry_highlights" text="Industry highlights" />
                                <SubmenuItem url="/info/vmp" text="VMP Sessions" />
                            </>
                        )}
                        {activeMenu === "/mentoring" && (
                            <>
                                <SubmenuItem url="/mentoring/tools" text="Tools" />
                                <SubmenuItem url="/mentoring/tracks" text="Tracks" />
                                <SubmenuItem url="/mentoring/trainees" text="Trainees" />
                                <SubmenuItem url="/mentoring/blog_posts" text="Blog posts" />
                            </>
                        )}
                        {activeMenu === "/admin" && (
                            <>
                                <SubmenuItem url="/admin/roadmap" text="Roadmap" />
                                <SubmenuItem url="/admin/users" text="Users" />
                                <SubmenuItem url="/admin/events" text="Event log" />
                                <SubmenuItem url="/admin/projectors" text="Projectors" />
                            </>
                        )}
                    </ListItem>
                </List>
            </Toolbar>
        </Collapse>
    );
}
