import { useNavigate, useParams } from "react-router-dom";
import { useSearch } from "@/services/message-service";

import { Box, Container, Divider, Link, Pagination, Typography } from "@mui/material";

export function SearchResults() {
    const params = useParams();
    const navigate = useNavigate();

    const term = params.term;
    const pageNumber = Number(params.page);
    const search = useSearch({ term: term ?? "", pageNumber: pageNumber });

    function onPageChange(page: number) {
        navigate(`../${page}`, {
            relative: "path",
        });
    }

    function onNavigate(slug: string) {
        navigate(`/academy/blog_posts/${slug}`);
    }

    return (
        <Container>
            <Typography variant="h2" component="h1">
                Search for <em>{term}</em> resulted in {search.data.value.numberOfHits} hit
                {search.data.value.numberOfHits > 1 && "s"}
            </Typography>
            {search.data.value.results.map((item) => (
                <Box key={item.slug}>
                    <Link
                        component="button"
                        onClick={() => onNavigate(item.slug)}
                        dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                    <Typography variant="body2" mt={1}>
                        <span style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: item.plainText }}></span>
                    </Typography>
                    <Divider
                        sx={{
                            mt: 3,
                            mb: 3,
                        }}
                    />
                </Box>
            ))}
            {search.data.value.numberOfHits === 0 && (
                <Box>
                    <Typography variant="body2">No datas found.</Typography>
                </Box>
            )}
            <Pagination
                page={pageNumber}
                count={Math.ceil(search.data.value.numberOfHits / 10)}
                onChange={(_, page) => onPageChange(page)}
            />
        </Container>
    );
}
