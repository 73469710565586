import { ListItemButton, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

type SubmenuItemProps = {
    url: string;
    text: string;
};

export function SubmenuItem(props: Readonly<SubmenuItemProps>) {
    const { url, text } = props;

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <ListItemButton
            selected={location.pathname.startsWith(url)}
            onClick={() => navigate(url)}
            sx={{
                textAlign: "center",
                textTransform: "uppercase",
                opacity: 0.6,
                width: "fit-content",
                "&.Mui-selected": {
                    opacity: 1,
                },
                "&.Mui-selected *": {
                    fontWeight: "bold",
                },
            }}
            data-cy={location.pathname}>
            <ListItemText>{text}</ListItemText>
        </ListItemButton>
    );
}
