import { EmptyGuid } from "@/constants";
import { DroppableModules } from "@/modules/admin/tracks/DroppableModules";
import { EditModule } from "@/modules/admin/tracks/EditModule";
import { useEditTrackViewModel } from "@/modules/admin/tracks/viewModels/useEditTrackViewModel";
import { Suspender } from "@/modules/common/components/Suspender";
import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export function EditTrack() {
    const { id } = useParams();
    const viewModel = useEditTrackViewModel(id!);
    const navigate = useNavigate();

    const remainingId = `${viewModel.id}-remaining`;

    const moduleLists = {
        [viewModel.id]: viewModel.modules,
        [remainingId]: viewModel.remaining,
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        if (!result.destination) {
            return;
        }

        const destination = moduleLists[result.destination.droppableId];
        const source = moduleLists[result.source.droppableId];

        if (!destination || !source) {
            return;
        }

        const modules = result.source.droppableId === remainingId ? viewModel.remaining : viewModel.modules;
        const module = modules[result.source.index];

        if (result.destination.droppableId !== result.source.droppableId) {
            viewModel.move(module, result.destination.index);
        } else {
            viewModel.reorder(module, result.source.index, result.destination.index);
        }
    };

    return (
        <Box display="flex" flexDirection="column" gap="1em">
            <TextField
                label="Name"
                required
                error={!viewModel.isNameValid()}
                value={viewModel.name}
                onChange={(e) => viewModel.rename(e.target.value)}
                sx={{ width: 750 }}
            />

            <TextField
                label="Slug"
                required
                error={!viewModel.isSlugValid()}
                value={viewModel.slug}
                disabled
                sx={{ width: 750 }}
            />

            <Box sx={{ margin: 1, display: "flex", flexDirection: "row" }}>
                <Suspender>
                    <DragDropContext onDragEnd={(result, provided) => onDragEnd(result, provided)}>
                        <DroppableModules id={viewModel.id} modules={viewModel.modules} />
                        <DroppableModules
                            id={remainingId}
                            modules={viewModel.remaining}
                            sx={{
                                maxHeight: "50vh",
                                overflow: "hidden",
                                borderLeft: "none",
                            }}>
                            <EditModule
                                icon={<AddIcon />}
                                module={{
                                    id: EmptyGuid,
                                    blogPost: null!,
                                    name: "",
                                    variants: [],
                                    defaultVariant: undefined!,
                                }}
                            />
                        </DroppableModules>
                    </DragDropContext>
                </Suspender>
            </Box>

            <Box>
                <Button
                    variant="contained"
                    disabled={!viewModel.isValid()}
                    onClick={() => viewModel.saveAsync().then(() => navigate(".."))}>
                    Save
                </Button>
                <Button color="secondary" onClick={() => navigate("..")}>
                    Cancel
                </Button>
                {id && (
                    <Button variant="contained" disabled={!viewModel.isValid()} onClick={() => viewModel.duplicateAsync()}>
                        Duplicate
                    </Button>
                )}
            </Box>
        </Box>
    );
}
