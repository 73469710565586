import { CurrentUserQueryResult } from "@/services/message-service";
import { createContext } from "react";

type AuthenticationContextType = {
    name: string;
    isAuthenticated: boolean;
    isAdministrator: boolean;
    isTimburrUser: boolean;
    isTrainee: boolean;
    authenticatedWith: CurrentUserQueryResult["authenticationScheme"] | undefined;
};

export const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined);
