import { formatDate } from "@/formatter";
import { CoachMeetingsPanel, CoachMeeting } from "@/modules/admin/trainee_overview/CoachMeetings";
import { Competencies } from "@/modules/admin/trainee_overview/Competencies";
import { MergeRequestsOverview } from "@/modules/admin/trainee_overview/MergeRequestsOverview";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { useTraineeViewModel } from "@/modules/admin/trainee_overview/viewModels/useTraineeViewModel";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { EmptyGuid } from "@/constants";

const MockMeetings: CoachMeeting[] = [
    { date: new Date("01-01-2024"), meetingReport: "It was a very intersting meeting." },
    { date: new Date("02-10-2024"), meetingReport: "The meeting was very boring." },
    { date: new Date("09-01-2022"), meetingReport: "We discussed software-related competencies." },
    { date: new Date("10-15-2024"), meetingReport: "Not much to mention." },
    { date: new Date("12-23-2024"), meetingReport: "It is going quite well." },
    { date: new Date("06-19-2024"), meetingReport: "It is not going very well." },
    { date: new Date("11-30-2024"), meetingReport: "Everything is fine." },
    { date: new Date("09-04-2023"), meetingReport: "We only talked about the weather." },
];

export function Trainee() {
    const beginningOfNextWeek = dayjs().startOf("week").add(1, "week").add(1, "day");

    const viewModel = useTraineeViewModel();
    const [editTrackDialogOpen, setEditTrackDialogOpen] = useState(false);
    const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(beginningOfNextWeek);
    const [currentTrackId, setCurrentTrackId] = useState(EmptyGuid);

    return (
        <Container maxWidth={false}>
            <div style={{ display: "flex" }}>
                <div style={{ flex: "row", width: "30%" }}>
                    <h1>{viewModel.trainee.name}</h1>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>{viewModel.trainee.emailAddress}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Start date</TableCell>
                                <TableCell>{formatDate(viewModel.trainee.startDate)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Background</TableCell>
                                <TableCell>{viewModel.trainee.background}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Proposed Secondment</TableCell>
                                <TableCell>{viewModel.trainee.proposedSecondment}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Mentors</TableCell>
                                <TableCell>{viewModel.trainee.mentors.join(", ")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <CoachMeetingsPanel meetings={MockMeetings} />
                <Competencies />
            </div>

            <h1>Track Overview</h1>
            <Box
                sx={{
                    width: "full",
                    border: 1,
                    borderColor: "rgba(224, 224, 224, 1)",
                }}>
                {viewModel.trackProgresses.map((track) => (
                    <Box
                        key={track.trackId}
                        sx={{
                            display: "flex",
                            margin: 2,
                            alignItems: "baseline",
                        }}>
                        <div style={{ marginRight: 4 }}>
                            <Typography variant="h4">{track.name}</Typography>
                            <Typography variant="h5">Started: {formatDate(track.startDate)}</Typography>
                            {track.endDate && <Typography variant="h5">Ended: {formatDate(track.endDate)}</Typography>}
                            {track.status === "Started" && (
                                <Button
                                    color="primary"
                                    size="small"
                                    endIcon={<CheckIcon fontSize="inherit" />}
                                    onClick={() => viewModel.endTrackAsync(track.trackId)}>
                                    End
                                </Button>
                            )}
                        </div>
                        <Stepper alternativeLabel>
                            {track.moduleProgresses.map((module) => (
                                <Step
                                    key={module.moduleId}
                                    completed={module.status === "Completed"}
                                    active={module.status === "Started"}>
                                    <StepLabel>{module.name}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                ))}
                <IconButton
                    color="primary"
                    onClick={() => setEditTrackDialogOpen(true)}
                    sx={{
                        margin: 2,
                    }}>
                    <Typography>
                        Assign track <EditIcon fontSize="small" />
                    </Typography>
                </IconButton>
            </Box>

            <Dialog open={editTrackDialogOpen} onClose={() => setEditTrackDialogOpen(false)} fullWidth>
                <DialogTitle>Assign track</DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em",
                    }}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <Select value={currentTrackId} onChange={(e) => setCurrentTrackId(e.target.value)} label="Track">
                            <MenuItem value={EmptyGuid}>&nbsp;</MenuItem>

                            {viewModel.allTracks.map((track) => (
                                <MenuItem key={track.id} value={track.id}>
                                    {track.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <DatePicker
                            autoFocus
                            value={startDate}
                            label="Start date"
                            onChange={(date) => setStartDate(date)}
                            slotProps={{
                                textField: { fullWidth: true },
                            }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={currentTrackId === EmptyGuid || startDate === null}
                        variant="contained"
                        onClick={async () => {
                            if (currentTrackId && startDate) {
                                await viewModel.assignTrackAsync(currentTrackId, startDate.toDate());
                                setEditTrackDialogOpen(false);
                            }
                        }}>
                        Save
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => {
                            setStartDate(beginningOfNextWeek);
                            setCurrentTrackId(EmptyGuid);
                            setEditTrackDialogOpen(false);
                        }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <h1>Merge Requests</h1>
            <MergeRequestsOverview mergeRequests={viewModel.trainee.mergeRequests} />
        </Container>
    );
}
