import { Box, Container, Drawer, Step, StepButton, StepLabel, Stepper, Toolbar, Typography } from "@mui/material";

import { BlogPostWrapper } from "@/modules/academy/BlogPostWrapper";
import { useTrackViewModel } from "@/modules/academy/tracks/viewModels/useTrackViewModel";

const drawerWidth = 240;

type Props = {
    slug: string;
    module?: string;
};

export function Track(props: Readonly<Props>) {
    const { slug, module } = props;

    const viewModel = useTrackViewModel(slug, module);

    if (viewModel === 404) {
        return <Typography>404 Not Found</Typography>;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
                }}>
                <Toolbar />
                <Toolbar />
                <Stepper
                    activeStep={viewModel.moduleIndex}
                    nonLinear
                    orientation="vertical"
                    sx={{
                        paddingX: "1em",
                        paddingTop: "1em",
                    }}>
                    {viewModel.modules.map((module) => {
                        return (
                            <Step key={module.name}>
                                <StepButton
                                    data-cy={module.name}
                                    color="inherit"
                                    onClick={() => viewModel.navigateToModule(module)}>
                                    <StepLabel>{module.name}</StepLabel>
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
            </Drawer>

            <Container data-cy="main" component="main" sx={{ flexGrow: 1, paddingBottom: "1em" }}>
                <Typography variant="h1">{viewModel.track}</Typography>

                <BlogPostWrapper slug={viewModel.post} />
            </Container>
        </Box>
    );
}
