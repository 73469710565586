import { useAuthenticationContext } from "@/modules/common/auth/useAuthenticationContext";
import {
    challengeQueryOptions,
    currentUserQueryOptions,
    getTrackProgressForTraineeQueryOptions,
    useApplicationVersion,
    useChangePassword,
    useLogin,
    useLogout,
} from "@/services/message-service";
import { ArgumentTypes } from "@/types/argumentTypes";
import { ArrayElement } from "@/types/arrayElement";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type LoginMutateAsync = ReturnType<typeof useLogin>["mutateAsync"];
type LoginMutateArguments = ArgumentTypes<LoginMutateAsync>;
type LoginType = ArrayElement<LoginMutateArguments>;

export const useAuthenticationViewModel = () => {
    const navigate = useNavigate();
    const version = useApplicationVersion();
    const queryClient = useQueryClient();
    const authenticationContext = useAuthenticationContext();
    const login = useLogin();
    const logout = useLogout();
    const changePassword = useChangePassword();

    async function loginAsync(args: LoginType) {
        const result = await login.mutateAsync(args);

        if (result.isSuccess) {
            queryClient.prefetchQuery(getTrackProgressForTraineeQueryOptions({ userId: undefined }));
        }

        return result;
    }

    return {
        isAuthenticated: authenticationContext.isAuthenticated,
        authenticatedWith: authenticationContext.authenticatedWith,
        logoutAsync: async () => {
            await logout.mutateAsync();
            navigate("/");
        },
        supportedAuthenticationSchemes: version.data.value.supportedAuthenticationSchemes,
        changePasswordAsync: async (currentPassword: string, password: string, passwordConfirmation: string) => {
            const result = await changePassword.mutateAsync({
                currentPassword,
                password,
                passwordConfirmation,
            });
            return result;
        },
        challengeAsync: async () => {
            const options = challengeQueryOptions({
                authenticationScheme: "Google",
                redirectUri: window.location.origin + "/flow_ended.html",
            });

            const data = await queryClient.fetchQuery(options);

            if (data.value.redirectUri) {
                window.google.open(data.value.redirectUri, "google_authentication", () => {
                    queryClient.invalidateQueries({
                        queryKey: currentUserQueryOptions().queryKey,
                    });
                    navigate("/");
                });
            }
        },
        loginAsync: async (username: string, password: string) => {
            const success = await loginAsync({
                authenticationScheme: "UsernamePassword",
                username: username,
                password: password,
            });

            return success.isSuccess;
        },
        loginAsAdminAsync: async () => {
            const success = await loginAsync({
                authenticationScheme: "Development",
                loginAs: "Admin",
            });

            if (success.isSuccess) {
                navigate("/");
            }
        },
        loginAsTraineeAsync: async () => {
            const success = await loginAsync({
                authenticationScheme: "Development",
                loginAs: "Trainee",
            });

            if (success.isSuccess) {
                navigate("/");
            }
        },
    };
};
