import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

interface BlogPostListMenuButtonProps {
    toggleSidebar: () => void;
}

export const BlogPostsListMenuButton: React.FC<BlogPostListMenuButtonProps> = ({ toggleSidebar }) => {
    return (
        <button
            title="All blog posts"
            onClick={toggleSidebar}
            style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
            }}>
            <ArrowBackIosNewIcon />
        </button>
    );
};
