import { Link } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import AcademySheetOverview from "@/modules/admin/ganttchart_academysheet/AcademySheetOverview";

export function MentoringMainPage() {
    return (
        <>
            <Link
                href="https://docs.google.com/spreadsheets/d/1bh0-_YazMiXV_wu8xoQ6-H_bT1SdqBZ9JAozPYlMt4o/edit?usp=drive_web&ouid=116797287808166969698"
                target="_blank"
                data-cy="mentoringPage"
                rel="noopener"
                marginLeft="0.8rem">
                Open Academy sheet <LaunchIcon fontSize="small" />
            </Link>
            <div style={{ margin: "0.8rem" }}>
                <AcademySheetOverview />
            </div>
        </>
    );
}
