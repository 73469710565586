import { useApplicationVersion } from "@/services/message-service";
import { useState } from "react";

const refreshCacheAndReload = async () => {
    try {
        if (window?.caches) {
            const { caches } = window;
            const cacheNames = await caches.keys();
            const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

            await Promise.all(cacheDeletionPromises);

            window.location.reload(true);
        }
    } catch (error) {
        console.log("An error occurred while deleting the cache.", true);
        console.log(error, true);
    }
};

export const useVersionModel = () => {
    const applicationVersion = useApplicationVersion();

    const [dismissed, setDismissed] = useState(false);
    const [version, setVersion] = useState<string | undefined>();

    const hasUpdate = applicationVersion.data.value.version !== version;

    if (!version) {
        setVersion(applicationVersion.data.value.version);
    }

    return {
        reloadAsync: async () => {
            setDismissed(true);
            await refreshCacheAndReload();
        },
        dismiss: () => setDismissed(true),
        hasUpdate: hasUpdate,
        dismissed: dismissed,
    };
};
