import { useSlugsInUse } from "@/services/message-service";
import { DialogButton, insertDirective$, usePublisher } from "@mdxeditor/editor";
import AbcIcon from "@mui/icons-material/Abc";

export const BlogPostButton = () => {
    const insertDirective = usePublisher(insertDirective$);
    const slugs = useSlugsInUse();

    return (
        <DialogButton
            tooltipTitle="Insert blog post"
            submitButtonTitle="Insert blog post"
            dialogInputPlaceholder="Path to the blog post"
            buttonContent={<AbcIcon />}
            autocompleteSuggestions={slugs.data.value}
            onSubmit={(path) => {
                const category = path.split("/")[0];
                const post = path.split("/")[1];
                if (post && category) {
                    insertDirective({
                        name: "blogPost",
                        type: "leafDirective",
                        attributes: { category: category, post: post },
                    });
                } else {
                    alert('Invalid URL. Use "category/post"');
                }
            }}
        />
    );
};
