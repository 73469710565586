import { SearchInput } from "@/modules/academy/searching/SearchInput";
import { Version } from "@/modules/common/Version";
import { Authentication } from "@/modules/common/auth/Authentication";
import { useAuthenticationContext } from "@/modules/common/auth/useAuthenticationContext";
import { Suspender } from "@/modules/common/components/Suspender";
import sogyoLogo from "@/sogyo.jpg";

import Home from "@mui/icons-material/Home";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import Info from "@mui/icons-material/Info";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import School from "@mui/icons-material/School";
import SchoolOutlined from "@mui/icons-material/SchoolOutlined";
import ViewQuilt from "@mui/icons-material/ViewQuilt";
import ViewQuiltOutlined from "@mui/icons-material/ViewQuiltOutlined";

import { IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

type MainMenuOption = "/" | "/academy" | "/info" | "/mentoring" | "/admin";

export type MainMenuProps = {
    activeMenu: MainMenuOption;
    setSubmenu: (submenu: MainMenuOption) => void;
};

export function MainMenu(props: Readonly<MainMenuProps>) {
    const { activeMenu, setSubmenu } = props;

    const navigate = useNavigate();
    const authenticationContext = useAuthenticationContext();

    return (
        <Toolbar>
            <Box data-cy="header_css" position="absolute" top={0} left={0} right={0} bottom={0} sx={{ textAlign: "center" }}>
                <IconButton
                    data-cy="home_button"
                    onClick={() => navigate("/")}
                    onMouseOver={() => setSubmenu("/")}
                    onFocus={() => setSubmenu("/")}
                    color="inherit"
                    size="large"
                    sx={{ flexDirection: "column" }}>
                    {activeMenu === "/" ? <Home /> : <HomeOutlined />}
                    <Typography>Home</Typography>
                </IconButton>

                {authenticationContext.isAuthenticated && (
                    <IconButton
                        data-cy="tracks_button"
                        onClick={() => navigate("/academy")}
                        onMouseOver={() => setSubmenu("/academy")}
                        onFocus={() => setSubmenu("/academy")}
                        color="inherit"
                        size="large"
                        sx={{ flexDirection: "column" }}>
                        {activeMenu === "/academy" ? <School /> : <SchoolOutlined />}
                        <Typography>Tracks</Typography>
                    </IconButton>
                )}

                {authenticationContext.isAuthenticated && (
                    <IconButton
                        data-cy="info_button"
                        onClick={() => navigate("/info")}
                        onMouseOver={() => setSubmenu("/info")}
                        onFocus={() => setSubmenu("/info")}
                        color="inherit"
                        size="large"
                        sx={{ flexDirection: "column" }}>
                        {activeMenu === "/info" ? <Info /> : <InfoOutlined />}
                        <Typography>Background Info</Typography>
                    </IconButton>
                )}

                {authenticationContext.isAdministrator && (
                    <IconButton
                        data-cy="mentor_button"
                        onClick={() => navigate("/mentoring")}
                        onMouseOver={() => setSubmenu("/mentoring")}
                        onFocus={() => setSubmenu("/mentoring")}
                        color="inherit"
                        size="large"
                        sx={{ flexDirection: "column" }}>
                        {activeMenu === "/mentoring" ? <PeopleAlt /> : <PeopleAltOutlined />}
                        <Typography>Mentoring</Typography>
                    </IconButton>
                )}

                {authenticationContext.isAdministrator && (
                    <IconButton
                        data-cy="admin_button"
                        onClick={() => navigate("/admin")}
                        onMouseOver={() => setSubmenu("/admin")}
                        onFocus={() => setSubmenu("/admin")}
                        color="inherit"
                        size="large"
                        sx={{ flexDirection: "column" }}>
                        {activeMenu === "/admin" ? <ViewQuilt /> : <ViewQuiltOutlined />}
                        <Typography>Admin</Typography>
                    </IconButton>
                )}
            </Box>
            <IconButton data-cy="logo" size="small" edge="start" sx={{ mr: 2, cursor: "default" }} disabled>
                <img src={sogyoLogo} alt="Sogyo logo" />
            </IconButton>
            <Suspender>
                <Version />
            </Suspender>

            <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center" alignItems="center" />
            {authenticationContext.isAuthenticated && <SearchInput />}
            <Authentication />
        </Toolbar>
    );
}
