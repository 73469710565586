import { EmptyGuid } from "@/constants";
import { useEditModuleViewModel } from "@/modules/admin/tracks/viewModels/useEditModuleViewModel";
import { EditTrackQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import { useState } from "react";

type Module = ArrayElement<EditTrackQueryResult["modules"]>;
type Variant = Module["defaultVariant"];

type Props = {
    icon: JSX.Element;
    module: Module;
};

export function EditModule(props: Readonly<Props>) {
    const { icon, module } = props;
    const [open, setOpen] = useState(false);
    const viewModel = useEditModuleViewModel(module);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        viewModel.reset();

        setOpen(false);
    };

    return (
        <div>
            <IconButton color="secondary" onClick={handleClickOpen}>
                {icon}
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>{module.id === EmptyGuid ? "Create module" : "Edit module"}</DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em",
                    }}>
                    <TextField
                        label="Name"
                        sx={{ marginTop: "1em" }}
                        required
                        error={viewModel.module.name !== module.name && viewModel.module.name === ""}
                        value={viewModel.module.name}
                        onChange={(e) => viewModel.rename(e.target.value)}
                    />
                    <Autocomplete
                        disablePortal
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                error={viewModel.module.blogPost !== module.blogPost && viewModel.module.blogPost === null}
                                label="Blog post"
                            />
                        )}
                        options={viewModel.blogPosts}
                        value={viewModel.module.blogPost}
                        onChange={(_, value) => viewModel.relocate(value!)}
                    />

                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel
                            required
                            error={module.variants.length > 0 && viewModel.module.variants.length === 0}
                            component="legend">
                            Variants
                        </FormLabel>
                        <FormGroup>
                            {viewModel.moduleVariants.map((variant) => (
                                <FormControlLabel
                                    key={variant}
                                    control={
                                        <Checkbox
                                            checked={viewModel.module.variants.includes(variant)}
                                            onChange={() => viewModel.toggleVariant(variant)}
                                            name={variant}
                                        />
                                    }
                                    label={variant}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>

                    <FormControl>
                        <FormLabel
                            required
                            error={
                                viewModel.module.defaultVariant !== module.defaultVariant &&
                                viewModel.module.defaultVariant === undefined
                            }
                            id="default-variant">
                            Default variant
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="default-variant"
                            name="default-variant"
                            value={viewModel.module.defaultVariant ?? null}
                            onChange={(e) => viewModel.setDefaultVariant(e.target.value as Variant)}>
                            {viewModel.module.variants.map((variant) => (
                                <FormControlLabel key={variant} value={variant} label={variant} control={<Radio />} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={!viewModel.isValid()}
                        onClick={async () => {
                            await viewModel.saveAsync();

                            if (viewModel.module.id === EmptyGuid) {
                                viewModel.reset();
                            }

                            handleClose();
                        }}>
                        Save
                    </Button>
                    <Button color="secondary" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
