import * as React from "react";
import { ExtendedTasks } from "./Tasks";

type TaskListProps = {
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: ExtendedTasks[];
    selectedTaskId: string;
    setSelectedTask: (taskId: string) => void;
    onExpanderClick: (task: ExtendedTasks) => void;
};

export const createTaskListLocal = (): React.FunctionComponent<TaskListProps> => {
    const TaskListComponent: React.FunctionComponent<TaskListProps> = ({
        rowHeight,
        rowWidth,
        tasks,
        fontFamily,
        fontSize,
        selectedTaskId,
        setSelectedTask,
        onExpanderClick,
    }) => {
        return (
            <div
                className="task-list-table-module-taskListWrapper"
                style={{
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                }}>
                {tasks.map((t) => {
                    let expanderSymbol = "";
                    if (t.hideChildren === false) {
                        expanderSymbol = "▼   ";
                    } else if (t.hideChildren === true) {
                        expanderSymbol = "▶   ";
                    }
                    return (
                        <div
                            className="task-list-table-module-taskListTableRow"
                            style={{ height: rowHeight }}
                            key={`${t.id}row`}
                            onClick={() => {
                                if (selectedTaskId === t.id) {
                                    setSelectedTask("");
                                } else {
                                    setSelectedTask(t.id);
                                }
                            }}>
                            <div className="task-list-table-module-taskListCell">
                                <div
                                    className={
                                        selectedTaskId === t.id ? "Gantt-Task-List-Checkbox__Checked" : "Gantt-Task-List-Checkbox"
                                    }></div>
                            </div>
                            {/**
                             * Name
                             */}
                            <div
                                className="task-list-table-module-taskListCell"
                                style={{
                                    minWidth: rowWidth,
                                    maxWidth: rowWidth,
                                }}
                                title={t.name}>
                                <div className="task-list-table-module-taskListNameWrapper">
                                    <div
                                        className={
                                            expanderSymbol
                                                ? "task-list-table-module-taskListExpander"
                                                : "task-list-table-module-taskListEmptyExpander"
                                        }
                                        onClick={(e) => {
                                            onExpanderClick(t);
                                            e.stopPropagation();
                                        }}>
                                        {expanderSymbol}
                                    </div>
                                    <div className="task-list-table-module-taskListCell">{t.name}</div>
                                </div>
                            </div>
                            {/**
                             * Mentors
                             */}
                            <div
                                className="task-list-table-module-taskListCell"
                                style={{
                                    minWidth: rowWidth,
                                    maxWidth: rowWidth,
                                }}
                                title={t.mentors}>
                                {t.mentors}
                            </div>
                            {/**
                             * Achtergrond
                             */}
                            <div
                                className="task-list-table-module-taskListCell"
                                style={{
                                    minWidth: rowWidth,
                                    maxWidth: rowWidth,
                                }}
                                title={t.achtergrond}>
                                {t.achtergrond}
                            </div>
                            {/**
                             * Proposed Secondment
                             */}
                            <div
                                className="task-list-table-module-taskListCell"
                                style={{
                                    minWidth: rowWidth,
                                    maxWidth: rowWidth,
                                }}
                                title={t.proposedsecondment}>
                                {t.proposedsecondment}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    return TaskListComponent;
};
