import "@mdxeditor/editor/style.css";
import {
    diffSourcePlugin,
    markdownShortcutPlugin,
    AdmonitionDirectiveDescriptor,
    directivesPlugin,
    headingsPlugin,
    imagePlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    quotePlugin,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    codeBlockPlugin,
    codeMirrorPlugin,
    KitchenSinkToolbar,
    MDXEditor,
    MDXEditorMethods,
    ButtonWithTooltip,
} from "@mdxeditor/editor";

import { YouTubeButton } from "@/modules/admin/blog_posts/YouTubeButton";
import { BlogPostButton } from "@/modules/admin/blog_posts/BlogPostButton";
import { BlogPostDirectiveDescriptor } from "@/modules/admin/blog_posts/BlogPostDirectiveDescriptor";
import { YoutubeDirectiveDescriptor } from "@/modules/admin/blog_posts/YoutubeDirectiveDescriptor";
import { Suspender } from "@/modules/common/components/Suspender";
import Add from "@mui/icons-material/Add";
import { useEffect, useRef, useState } from "react";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import { Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSlugsInUse } from "@/services/message-service";

type BlogPost = {
    title: string;
    slug: string;
    markdown: string;
};

type Props = {
    blogPost: BlogPost;
    onSaveClick: (blogPost: BlogPost) => void;
};

export function BlogPostEditor(props: Readonly<Props>) {
    const slugsInUse = useSlugsInUse();

    const [title, setTitle] = useState(props.blogPost.title);
    const [markdown, setMarkdown] = useState(props.blogPost.markdown);
    const [slug, setSlug] = useState(props.blogPost.slug);
    const ref = useRef<MDXEditorMethods>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (ref.current) {
            ref.current.setMarkdown(markdown);
        }
    }, [markdown]);

    return (
        <Box display="flex" flexDirection="column" gap="1em">
            <TextField
                label="Title"
                fullWidth
                required
                error={title === ""}
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value);
                    if (props.blogPost.slug === "") {
                        setSlug(e.target.value.toLocaleLowerCase().replaceAll(" ", "_"));
                    }
                }}
                sx={{ width: 750 }}
            />

            <TextField
                label="Slug"
                required
                error={slug !== props.blogPost.slug && slugsInUse.data.value.includes(slug)}
                value={slug}
                disabled
                fullWidth
                sx={{ marginTop: "1em", width: 750 }}
            />

            <MDXEditor
                ref={ref}
                markdown={markdown}
                onChange={(content) => setMarkdown(content)}
                plugins={[
                    toolbarPlugin({
                        toolbarContents: () => [
                            <KitchenSinkToolbar key="kitchen_sink" />,
                            <YouTubeButton key="youtube" />,
                            <Suspender key="blogPost">
                                <BlogPostButton />
                            </Suspender>,
                            <ButtonWithTooltip
                                key="new_blog_post"
                                title="Make new blog post"
                                onClick={() => navigate("/mentoring/blog_posts")}>
                                <Add />
                            </ButtonWithTooltip>,
                            <ButtonWithTooltip
                                key="save_blog_post"
                                title="Save blog post"
                                onClick={() =>
                                    props.onSaveClick({
                                        slug: slug,
                                        title: title,
                                        markdown: markdown,
                                    })
                                }>
                                <SaveAsOutlinedIcon />
                            </ButtonWithTooltip>,
                        ],
                    }),
                    listsPlugin(),
                    quotePlugin(),
                    headingsPlugin(),
                    linkPlugin(),
                    linkDialogPlugin(),
                    imagePlugin({
                        imageAutocompleteSuggestions: ["https://via.placeholder.com/150", "https://via.placeholder.com/150"],
                        imageUploadHandler: async () => Promise.resolve("https://picsum.photos/200/300"),
                    }),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    codeBlockPlugin({ defaultCodeBlockLanguage: "txt" }),
                    codeMirrorPlugin({
                        codeBlockLanguages: {
                            bash: "shell",
                            css: "CSS",
                            html: "html",
                            java: "java",
                            js: "JavaScript",
                            tsx: "TypeScript",
                            txt: "text",
                            yml: "yml",
                        },
                    }),
                    directivesPlugin({
                        directiveDescriptors: [
                            BlogPostDirectiveDescriptor,
                            YoutubeDirectiveDescriptor,
                            AdmonitionDirectiveDescriptor,
                        ],
                    }),
                    diffSourcePlugin({ viewMode: "rich-text", diffMarkdown: markdown }),
                    markdownShortcutPlugin(),
                ]}
            />
        </Box>
    );
}
