/* eslint-disable react/prop-types */

import { LeafDirective } from "mdast-util-directive";
import { DirectiveDescriptor } from "@mdxeditor/editor";

interface YoutubeDirectiveNode extends LeafDirective {
    name: "youtube";
    attributes: { id: string };
}

export const YoutubeDirectiveDescriptor: DirectiveDescriptor<YoutubeDirectiveNode> = {
    name: "youtube",
    type: "leafDirective",
    testNode(node) {
        return node.name === "youtube";
    },
    attributes: ["id"],
    hasChildren: false,
    Editor: ({ mdastNode, lexicalNode, parentEditor }) => {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <button
                    onClick={() => {
                        parentEditor.update(() => {
                            lexicalNode.selectNext();
                            lexicalNode.remove();
                        });
                    }}>
                    delete
                </button>
                <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${mdastNode.attributes?.id}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
        );
    },
};
