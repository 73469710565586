import { Task } from "gantt-task-react";
import { useAllTrainees } from "@/services/message-service";

const calculateMainProjectProgress = () => {
    const currentDate = new Date(2021, 5, 1);
    const totalTime = currentDate.getTime() - new Date(2021, 5, 1).getTime();
    const totalDuration = 3 * 30 * 24 * 60 * 60 * 1000;
    const progress = (totalTime / totalDuration) * 100;
    return Math.min(Math.max(progress, 0), 100);
};

export interface ExtendedTasks extends Task {
    dagenThuis?: string;
    mentors?: string;
    achtergrond?: string;
    proposedsecondment?: string;
    emailAddress?: string;
}

export const useTraineesTasks = () => {
    const traineesInfo = useAllTrainees();

    return traineesInfo.data.value.trainees.map((trainee) => {
        const traineeEndDate = new Date(trainee.startDate);
        traineeEndDate.setMonth(traineeEndDate.getMonth() + 3);

        return {
            start: new Date(trainee.startDate),
            end: traineeEndDate,
            name: trainee.name,
            id: `Trainee_${trainee.id}`,
            emailAddress: trainee.emailAddress,
            proposedsecondment: trainee.proposedSecondment,
            mentors: trainee.mentors.join(", "),
            achtergrond: trainee.background,
            type: "task" as const,
            hideChildren: false,
            project: "MainProject",
            progress: 0,
        };
    });
};

export const useTasks = () => {
    const traineeTasks = useTraineesTasks();

    const mainProjectStartDate = new Date(2021, 5, 1);
    const mainProjectEndDate = new Date(2021, 8, 1);

    const mainProject: ExtendedTasks = {
        start: mainProjectStartDate,
        end: mainProjectEndDate,
        name: "Example Trainees",
        id: "MainProject",
        type: "project",
        hideChildren: false,
        progress: calculateMainProjectProgress(),
    };

    const tasks: ExtendedTasks[] = [mainProject, ...traineeTasks];

    return tasks;
};
export const getStartEndDateForProject = (tasks: ExtendedTasks[], projectId: string) => {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (const element of projectTasks) {
        const task = element;
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
};
