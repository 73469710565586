import { LoadingIndicator } from "@/modules/common/components/LoadingIndicator";
import React, { Suspense } from "react";

type Props = {
    children: React.ReactElement;
};

export function Suspender(props: Readonly<Props>) {
    const { children } = props;
    return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>;
}
