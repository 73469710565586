import { Track } from "@/modules/academy/tracks/Track";
import { Suspender } from "@/modules/common/components/Suspender";
import { useNavigate, useParams } from "react-router-dom";

export function TrackWrapper() {
    const { track, module } = useParams();
    const navigate = useNavigate();

    if (!track) {
        navigate("/");
    } else {
        return (
            <Suspender>
                <Track slug={track} module={module} />
            </Suspender>
        );
    }
}
