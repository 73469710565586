import { useVersionModel } from "@/modules/common/models/useVersionModel";
import UpdateIcon from "@mui/icons-material/Update";
import { Alert, Button, IconButton, Slide, SlideProps, Snackbar, Stack, Tooltip } from "@mui/material";

type TransitionProps = Omit<SlideProps, "direction">;

function transitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

export function Version() {
    const viewModel = useVersionModel();

    return (
        <>
            {viewModel.hasUpdate && (
                <Tooltip title="Reload new version" arrow>
                    <IconButton color="inherit" onClick={() => viewModel.reloadAsync()}>
                        <UpdateIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Snackbar
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                autoHideDuration={6000}
                open={viewModel.hasUpdate && !viewModel.dismissed}
                onClose={() => viewModel.dismiss()}
                TransitionComponent={transitionDown}>
                <Alert
                    onClose={() => viewModel.dismiss()}
                    severity="info"
                    action={
                        <Stack direction="row">
                            <Button color="inherit" size="small" onClick={() => viewModel.reloadAsync()}>
                                Reload
                            </Button>
                            <Button color="inherit" size="small" onClick={() => viewModel.dismiss()}>
                                Dismiss
                            </Button>
                        </Stack>
                    }>
                    A new version is available!
                </Alert>
            </Snackbar>
        </>
    );
}
