import { useGetTrackProgressForTrainee } from "@/services/message-service";
import { Navigate } from "react-router-dom";

export function UserTrack() {
    const tracks = useGetTrackProgressForTrainee({ userId: undefined });
    const trackProgress = tracks.data.value.trackProgresses[0];
    const module =
        trackProgress.moduleProgresses.find((moduleProgress) => moduleProgress.status === "Started") ??
        trackProgress.moduleProgresses[0];

    return <Navigate to={`/academy/tracks/${encodeURI(trackProgress.slug)}/${encodeURI(module.blogPost)}`} />;
}
