import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ShowDevToolsInProd } from "@/modules/admin/ShowDevToolsInProd";
import { AuthenticationContextProvider } from "@/modules/common/auth/AuthenticationContextProvider";
import { Suspender } from "@/modules/common/components/Suspender";
import { Routes } from "@/modules/common/navigation/Routes";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, createTheme } from "@mui/material";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import TopBarProgress from "react-topbar-progress-indicator";

const theme = createTheme({
    palette: {
        primary: {
            main: "#007936",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#00AC4D",
            contrastText: "#FFFFFF",
        },
        mode: "light",
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: "2em",
                    fontWeight: "bolder",
                    margin: ".67em 0",
                },
                h2: {
                    fontSize: "1.5em",
                    fontWeight: "bolder",
                    margin: ".75em 0",
                },
                h3: {
                    fontSize: "1.17em",
                    fontWeight: "bolder",
                    margin: ".83em 0",
                },
                h4: {
                    fontSize: "1em",
                    fontWeight: "bolder",
                    margin: "1.12em 0",
                },
                h5: {
                    fontSize: "0.83em",
                    fontWeight: "bolder",
                    margin: "1.12em 0",
                },
                h6: {
                    fontSize: "0.67em",
                    fontWeight: "bolder",
                    margin: "1.12em 0",
                },
            },
        },
    },
});

export function App() {
    TopBarProgress.config({
        barColors: {
            "0": theme.palette.primary.main,
            "1.0": "#999999",
        },
        shadowBlur: 0,
    });

    const queryClient = new QueryClient({
        mutationCache: new MutationCache({
            onError(error, variables, context, mutation) {
                console.error(mutation, error, variables, context, error);
            },
        }),
        queryCache: new QueryCache({
            onError(error, query) {
                console.error(query, error);
            },
        }),
    });

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                <CssBaseline />
                <QueryClientProvider client={queryClient}>
                    <Suspender>
                        <AuthenticationContextProvider>
                            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                            <Routes />
                            <ShowDevToolsInProd />
                        </AuthenticationContextProvider>
                    </Suspender>
                </QueryClientProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
