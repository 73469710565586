import { Dialog, DialogTitle, DialogContent, Grid, Typography, DialogActions, Button } from "@mui/material";
import { ArrayElement } from "@/types/arrayElement";
import { EventLogsQueryResult } from "@/services/message-service";
import { formatDateTime } from "@/formatter";

type EventDetailsDialogProps = {
    selectedEvent: ArrayElement<EventLogsQueryResult>;
    onClose: () => void;
};

export function EventDetailsDialog(props: Readonly<EventDetailsDialogProps>) {
    const { selectedEvent, onClose } = props;

    return (
        <Dialog open={true} onClose={() => onClose()} scroll="paper">
            <DialogTitle>
                {selectedEvent.sequenceNumber}: {selectedEvent.eventMetadata.eventName}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid xs={6} item>
                        <Typography variant="caption">Timestamp</Typography>
                    </Grid>
                    <Grid xs={6} item>
                        <Typography variant="body1">{formatDateTime(selectedEvent.eventMetadata.timestamp)}</Typography>
                    </Grid>

                    <Grid xs={6} item>
                        <Typography variant="caption">Caused by</Typography>
                    </Grid>
                    <Grid xs={6} item>
                        <Typography variant="body1">{selectedEvent.eventMetadata.causedByEmailAddress}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogContent dividers>
                <Typography component="pre">{JSON.stringify(selectedEvent.event, null, "  ")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
