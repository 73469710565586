import { EditTrackQueryResult, useEditTrack, useSaveTrack } from "@/services/message-service";
import { useState } from "react";
import { EmptyGuid } from "@/constants";
import { useNavigate } from "react-router-dom";
import { ArrayElement } from "@/types/arrayElement";

type Module = ArrayElement<EditTrackQueryResult["modules"]>;

export const useEditTrackViewModel = (id: string) => {
    const navigate = useNavigate();
    const model = useEditTrack({ id });
    const saveTrack = useSaveTrack();

    const [name, setName] = useState(model.data.value.track.name);
    const [slug, setSlug] = useState(model.data.value.track.slug);
    const [modules, setModules] = useState(model.data.value.track.modules);

    const remaining = model.data.value.modules
        .filter((module) => modules.findIndex((m) => m.id === module.id) === -1)
        .toSorted((a, b) => a.name.localeCompare(b.name));

    return {
        id: model.data.value.track.id,
        name: name,
        slug: slug,
        modules: modules,
        remaining: remaining,
        reorder(module: Module, from: number, to: number) {
            if (modules.findIndex((m) => m.id === module.id) !== -1) {
                const newModules = [...modules];

                const [removed] = newModules.splice(from, 1);
                newModules.splice(to, 0, removed);

                setModules(newModules);
            }
        },
        move(module: Module, index: number) {
            const moduleIndex = modules.indexOf(module);

            if (moduleIndex !== -1) {
                const newModules = modules.toSpliced(moduleIndex, 1);

                setModules(newModules);
            } else {
                const newModules = modules.toSpliced(index, 0, module);

                setModules(newModules);
            }
        },
        rename(name: string) {
            setName(name);

            if (model.data.value.track.slug === "") {
                setSlug(name.toLocaleLowerCase().replaceAll(" ", "_"));
            }
        },
        isNameValid() {
            return model.data.value.track.name === name || name !== "";
        },
        isSlugValid() {
            return model.data.value.track.slug === slug || /^[a-z0-9_]+$/.test(slug);
        },
        isValid() {
            return !!name;
        },
        async saveAsync() {
            await saveTrack.mutateAsync({
                id: id,
                name: name,
                slug: slug,
                moduleIds: modules.map((module) => module.id),
            });
        },
        async duplicateAsync() {
            await saveTrack.mutateAsync({
                id: EmptyGuid,
                name: `${name} copy`,
                slug: `${slug}_copy`,
                moduleIds: modules.map((module) => module.id),
            });
            navigate("..");
        },
    };
};
