import { formatDate } from "@/formatter";
import { Box, Button, ButtonGroup } from "@mui/material";
import { useState } from "react";

export type CoachMeeting = {
    date: Date;
    meetingReport: string;
};

export function CoachMeetingsPanel({ meetings }: Readonly<{ meetings: CoachMeeting[] }>) {
    const [meetingReport, setMeetingReport] = useState<string>("");

    const updateMeetingReport = (newMeetingReport: string) => {
        setMeetingReport(newMeetingReport);
    };

    return (
        <div style={{ width: "30%" }}>
            <h1>Coaching Meetings</h1>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <ButtonGroup orientation="vertical" aria-label="Vertical button group" variant="text">
                    {meetings.map((meeting) => {
                        const dateString = formatDate(meeting.date);
                        return (
                            <Button key={dateString} onClick={() => updateMeetingReport(meeting.meetingReport)}>
                                {dateString}
                            </Button>
                        );
                    })}
                </ButtonGroup>

                <p style={{ padding: 5 }}>{meetingReport}</p>
            </Box>
        </div>
    );
}
