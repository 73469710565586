import { EditModule } from "@/modules/admin/tracks/EditModule";
import { EditTrackQueryResult } from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";
import { Draggable } from "@hello-pangea/dnd";
import EditIcon from "@mui/icons-material/Edit";
import { Theme, Typography, styled, useTheme } from "@mui/material";

const DragItem = styled("div", {
    shouldForwardProp: (prop) => prop !== "isDragging",
})(({ isDragging, theme }: { isDragging: boolean; theme: Theme }) => ({
    padding: 10,
    borderRadius: 4,
    boxShadow: isDragging ? theme.shadows[5] : theme.shadows[0],
    border: "1px solid #000",
    background: theme.palette.primary.contrastText,
    margin: theme.spacing(0, 0, 1, 0),
    width: 250,
    userSelect: "none",
}));

type Module = ArrayElement<EditTrackQueryResult["modules"]>;

export function DraggableModule(props: Readonly<{ module: Module; index: number }>) {
    const { module, index } = props;
    const theme = useTheme();

    return (
        <Draggable key={module.id} draggableId={module.id} index={index}>
            {(provided, snapshot) => (
                <DragItem
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    theme={theme}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                    <Typography sx={{ flexGrow: 1 }}>{module.name}</Typography>
                    <EditModule icon={<EditIcon />} module={module} />
                </DragItem>
            )}
        </Draggable>
    );
}
