import { useAuthenticationContext } from "@/modules/common/auth/useAuthenticationContext";
import { Suspender } from "@/modules/common/components/Suspender";
import { Navigate, Outlet } from "react-router-dom";

export function AcademyLayout() {
    const authenticationContext = useAuthenticationContext();

    if (!authenticationContext.isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Suspender>
            <Outlet />
        </Suspender>
    );
}
