import { Box } from "@mui/material";

type Props = {
    src: string;
    alt?: string;
};

export function Image(props: Readonly<Props>) {
    const { src, alt } = props;

    return (
        <Box
            component="img"
            sx={{
                maxWidth: "100%",
            }}
            alt={alt}
            src={src}
        />
    );
}
