import React, { useState } from "react";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import { useTasks, getStartEndDateForProject, ExtendedTasks } from "./Tasks";
import { ViewSwitcher } from "./ViewSwitcher";
import { TaskListHeader } from "./TaskListHeader";
import "gantt-task-react/dist/index.css";
import "./style.css";
import { createTaskListLocal } from "./TasksListTable";

const AcademySheetOverview = () => {
    const [view, setView] = useState<ViewMode>(ViewMode.Week);
    const [tasks, setTasks] = useState<ExtendedTasks[]>(useTasks());
    const [isChecked, setIsChecked] = useState(true);
    let columnWidth = 60;

    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    const handleTaskChange = (task: ExtendedTasks) => {
        let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
            if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
            }
        }
        setTasks(newTasks);
    };

    const listCellWidth = isChecked ? "155px" : "";

    const TaskListComponent = createTaskListLocal();

    interface CustomHeaderProps {
        headerHeight: number;
        rowWidth: string;
        fontFamily: string;
        fontSize: string;
    }

    const customHeader: React.FC<CustomHeaderProps> = () => <TaskListHeader />;

    const customTable = (props: {
        rowHeight: number;
        rowWidth: string;
        fontFamily: string;
        fontSize: string;
        locale: string;
        tasks: Task[];
        selectedTaskId: string;
        setSelectedTask: (taskId: string) => void;
        onExpanderClick: (task: ExtendedTasks) => void;
    }) => <TaskListComponent {...props} />;

    return (
        <div>
            <ViewSwitcher
                onViewModeChange={(viewMode: ViewMode) => setView(viewMode)}
                onViewListChange={setIsChecked}
                isChecked={isChecked}
            />
            <h1>Academy Sheet: Trainees Overview</h1>
            <Gantt
                tasks={tasks}
                viewMode={view}
                onDateChange={handleTaskChange}
                listCellWidth={listCellWidth}
                columnWidth={columnWidth}
                TaskListHeader={customHeader}
                TaskListTable={customTable}
            />
        </div>
    );
};

export default AcademySheetOverview;
