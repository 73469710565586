import { useAuthenticationContext } from "@/modules/common/auth/useAuthenticationContext";
import { lazy } from "react";

const ReactQueryDevtoolsProduction = lazy(() =>
    import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
        default: d.ReactQueryDevtools,
    }))
);

export const ShowDevToolsInProd = () => {
    const authenticationContext = useAuthenticationContext();

    return authenticationContext.isAdministrator && <ReactQueryDevtoolsProduction buttonPosition="bottom-left" />;
};
