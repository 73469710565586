import { AuthenticationContext } from "@/modules/common/auth/authenticationContext";
import { useCurrentUser } from "@/services/message-service";
import { PropsWithChildren, useMemo } from "react";

export function AuthenticationContextProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const currentUser = useCurrentUser();
    const data = currentUser.data.value;

    const value = useMemo(() => {
        return {
            name: data.name,
            isAuthenticated: data.roles.length > 0,
            isAdministrator: data.roles.includes("Admin") ?? false,
            isTimburrUser: data.roles.includes("User") ?? false,
            isTrainee: data.roles.includes("Trainee") ?? false,
            authenticatedWith: data.authenticationScheme,
        };
    }, [data]);

    return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}
