import { ArrayElement } from "@/types/arrayElement";
import { EventLogsQueryResult, useEventLogs } from "@/services/message-service";
import { useState } from "react";

export const useEventsViewModel = () => {
    const eventLogs = useEventLogs();

    type EventLog = ArrayElement<EventLogsQueryResult>;

    const [selectedEvent, setSelectedEvent] = useState<EventLog | null>(null);
    const [selectedAggregate, setSelectedAggregate] = useState<string | null>(null);

    return {
        events: eventLogs.data.value,
        selectedEvent: selectedEvent,
        selectEvent: (event: EventLog) => setSelectedEvent(event),
        deselectEvent: () => setSelectedEvent(null),
        selectAggregate: (aggregate: string) => setSelectedAggregate(aggregate),
        deselectAggregate: () => setSelectedAggregate(null),
        selectedAggregate: selectedAggregate,
        aggregateEvents: eventLogs.data.value
            .filter((event) => event.aggregateId === selectedAggregate)
            .toSorted((a, b) => a.sequenceNumber - b.sequenceNumber),
    };
};
