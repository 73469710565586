import { useTraineesViewModel } from "@/modules/admin/trainee_overview/viewModels/useTraineesViewModel";
import { useAssignTrack, useEndTrack, useGetTrackProgressForTrainee, useTrackOverview } from "@/services/message-service";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

export const useTraineeViewModel = () => {
    const params = useParams();
    const viewModel = useTraineesViewModel();
    const tracks = useTrackOverview();
    const assignTrack = useAssignTrack();
    const endTrack = useEndTrack();

    const traineeId = params.trainee!;
    const trainee = viewModel.trainees.find((t) => t.id == traineeId)!;
    const userId = trainee.userId;

    return {
        allTracks: tracks.data.value.tracks.filter(
            (track) => trainee.trackProgresses.find((trackProgress) => trackProgress.trackId === track.id) === undefined
        ),
        trackProgresses: useGetTrackProgressForTrainee({ userId }).data.value.trackProgresses,
        trainee: trainee,
        assignTrackAsync: (trackId: string, startDate: Date) => {
            return assignTrack.mutateAsync({
                id: traineeId,
                trackId: trackId,
                startDate: startDate,
            });
        },
        endTrackAsync: (trackId: string) => {
            const track = trainee.trackProgresses.find((trackProgress) => trackProgress.trackId === trackId)!;

            return endTrack.mutateAsync({
                id: traineeId,
                trackId: trackId,
                endDate: dayjs().isBefore(track.startDate) ? track.startDate : dayjs().toDate(),
            });
        },
    };
};
