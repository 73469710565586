import { useGetBlogPostBySlug, useUpdateBlogPost } from "@/services/message-service";

export const useEditBlogPostViewModel = (slug: string) => {
    const document = useGetBlogPostBySlug({ slug: slug });
    const updateBlogPost = useUpdateBlogPost();

    return {
        blogPost: document.data.value,
        saveAsync: async (id: string, title: string, markdown: string) => {
            await updateBlogPost.mutateAsync({
                id: id,
                markdown: markdown,
                title: title,
            });
        },
    };
};
