import { useAuthenticationViewModel } from "@/modules/common/auth/viewModels/useAuthenticationViewModel";
import { Alert, Avatar, Box, Button, Container, TextField } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export function Login() {
    const viewModel = useAuthenticationViewModel();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginFailed, setLoginFailed] = useState(false);

    useEffect(() => {
        if (viewModel.isAuthenticated) {
            setLoginFailed(false);
            navigate("/");
        }
    }, [viewModel.isAuthenticated, navigate]);

    async function onLogin(e: FormEvent) {
        e.preventDefault();
        const success = await viewModel.loginAsync(username, password);

        setLoginFailed(!success);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Box component="form" onSubmit={(e) => onLogin(e)} noValidate sx={{ mt: 1 }}>
                    {loginFailed && !viewModel.isAuthenticated && <Alert severity="error">Invalid username/password</Alert>}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
