import { Link as RouterLink, useParams } from "react-router-dom";

import LaunchIcon from "@mui/icons-material/Launch";
import { Link as MUILink } from "@mui/material";

type Props = React.PropsWithChildren & {
    href: string;
    className?: string;
};

export function Link(props: Props) {
    const { href, className, children } = props;
    const { track } = useParams();

    if (href.startsWith("#")) {
        return (
            <MUILink className={className} href={href}>
                {children}
            </MUILink>
        );
    }

    if (href.startsWith("http://") || href.startsWith("https://")) {
        return (
            <MUILink className={className} href={href} target="_blank" rel="noopener">
                {children} <LaunchIcon fontSize="small" />
            </MUILink>
        );
    }

    if (href.startsWith("/")) {
        return (
            <MUILink component={RouterLink} to={href} className={className}>
                {children}
            </MUILink>
        );
    }

    if (track) {
        const to = `/academy/tracks/${track}/${href}`;

        return (
            <MUILink component={RouterLink} to={to} className={className}>
                {children}
            </MUILink>
        );
    }

    if (href.startsWith("books")) {
        const to = `/info/${href}`;

        return (
            <MUILink component={RouterLink} to={to} className={className}>
                {children}
            </MUILink>
        );
    }
    return (
        <MUILink component={RouterLink} to={`/academy/blog_posts/${href}`} className={className}>
            {children}
        </MUILink>
    );
}
