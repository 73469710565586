import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Switch,
    Divider,
    FormControlLabel,
    Alert,
} from "@mui/material";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { useUsersViewModel } from "@/modules/admin/user_administration/viewModels/useUsersViewModel";

type Props = {
    open: boolean;
    onClose: () => void;
};

export function NewUser(props: Readonly<Props>) {
    const { open, onClose } = props;
    const viewModel = useUsersViewModel();

    const [validationMessages, setValidationMessages] = useState<string[]>([]);

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [externalId, setExternalId] = useState("");

    const [isTrainee, setIsTrainee] = useState(false);
    const [mentors, setMentors] = useState<string[]>([]);
    const [background, setBackground] = useState("");
    const [proposedSecondment, setProposedSecondment] = useState("");
    const [startDate, setStartDate] = useState<Dayjs | null>();

    async function handleClose() {
        const result = await viewModel.createNewUserAsync({
            name,
            emailAddress,
            password,
            externalId,
            mentors: isTrainee ? mentors : [],
            background: isTrainee ? background : undefined,
            proposedSecondment: isTrainee ? proposedSecondment : undefined,
            startDate: isTrainee && startDate ? startDate.add(8, "hours").toDate() : undefined,
        });

        if (result.isSuccess) {
            onClose();
        } else {
            setValidationMessages(result.errors.map((error) => error.message));
        }
    }

    function handleCancel() {
        onClose();
    }

    return (
        <Dialog onClose={handleCancel} open={open}>
            <DialogTitle>Add new user</DialogTitle>
            <DialogContent>
                {validationMessages.length > 0 && (
                    <Alert severity="error">
                        <ul>
                            {validationMessages.map((message) => (
                                <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="E-mail address"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Password"
                    autoComplete="new-password"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="External id"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={externalId}
                    onChange={(e) => setExternalId(e.target.value)}
                />

                <Divider />

                <FormControlLabel
                    control={<Switch checked={isTrainee} onChange={(e) => setIsTrainee(e.target.checked)} />}
                    label="Is trainee?"
                />
                <DatePicker
                    autoFocus
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    label="Start date"
                    disabled={!isTrainee}
                    slotProps={{ textField: { fullWidth: true } }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Background"
                    type="text"
                    disabled={!isTrainee}
                    fullWidth
                    variant="outlined"
                    value={background}
                    onChange={(e) => setBackground(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Proposed secondment"
                    type="text"
                    disabled={!isTrainee}
                    fullWidth
                    variant="outlined"
                    value={proposedSecondment}
                    onChange={(e) => setProposedSecondment(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Mentors"
                    type="text"
                    disabled={!isTrainee}
                    fullWidth
                    variant="outlined"
                    value={mentors.join(",")}
                    onChange={(e) => setMentors(e.target.value.split(","))}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleClose}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
