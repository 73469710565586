import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

export function TraineeIndex() {
    return (
        <Container maxWidth={false}>
            <Outlet />
        </Container>
    );
}
