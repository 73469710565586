import { AcademyLayout } from "@/modules/academy/AcademyLayout";
import { AcademyMainPage } from "@/modules/academy/AcademyMainPage";
import { BlogPostWrapper } from "@/modules/academy/BlogPostWrapper";
import { SogyoSites } from "@/modules/academy/SogyoSites";
import { Library } from "@/modules/academy/books/Library";
import { SearchResults } from "@/modules/academy/searching/SearchResults";
import { TrackWrapper } from "@/modules/academy/tracks/TrackWrapper";
import { UserTrack } from "@/modules/academy/tracks/UserTrack";
import { AdminLayout } from "@/modules/admin/AdminLayout";
import { MentoringMainPage } from "@/modules/admin/MentoringMainPage";
import { BlogPostIndex } from "@/modules/admin/blog_posts/BlogPostIndex";
import { CreateBlogPost } from "@/modules/admin/blog_posts/CreateBlogPost";
import { EditBlogPost } from "@/modules/admin/blog_posts/EditBlogPost";
import { Events } from "@/modules/admin/events/Events";
import { ProjectorOverview } from "@/modules/admin/projectors/ProjectorOverview";
import { Roadmap } from "@/modules/admin/roadmap/Roadmap";
import { EditTrack } from "@/modules/admin/tracks/EditTrack";
import { Overview } from "@/modules/admin/tracks/Overview";
import { TrackIndex } from "@/modules/admin/tracks/TrackIndex";
import { Trainee } from "@/modules/admin/trainee_overview/Trainee";
import { TraineeIndex } from "@/modules/admin/trainee_overview/TraineeIndex";
import { Trainees } from "@/modules/admin/trainee_overview/Trainees";
import { Users } from "@/modules/admin/user_administration/Users";
import { CommonLayout } from "@/modules/common/CommonLayout";
import { ChangePassword } from "@/modules/common/auth/ChangePassword";
import { Login } from "@/modules/common/auth/Login";
import { Container } from "@mui/system";
import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";

const routes: RouteObject[] = [
    {
        path: "/",
        element: <CommonLayout />,
        children: [
            {
                index: true,
                element: <AcademyMainPage />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "academy/*",
                element: <AcademyLayout />,
                children: [
                    {
                        index: true,
                        element: <UserTrack />,
                    },
                    {
                        path: "tracks/:track/:module?",
                        element: <TrackWrapper />,
                    },
                    {
                        path: "blog_posts/:slug",
                        element: (
                            <Container component="main" sx={{ paddingBottom: "1em" }}>
                                <BlogPostWrapper />
                            </Container>
                        ),
                    },
                    {
                        path: "search/:term/:page",
                        element: <SearchResults />,
                    },
                    {
                        path: "change_password",
                        element: <ChangePassword />,
                    },
                ],
            },
            {
                path: "info/*",
                element: <AcademyLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="/info/sogyo_sites" />,
                    },
                    {
                        path: "library",
                        element: <Library />,
                    },
                    {
                        path: "industry_highlights",
                        element: (
                            <Container component="main" sx={{ paddingBottom: "1em" }}>
                                <BlogPostWrapper slug="highlights_intro" />
                            </Container>
                        ),
                    },
                    {
                        path: "vmp",
                        element: (
                            <Container component="main" sx={{ paddingBottom: "1em" }}>
                                <BlogPostWrapper slug="vmp" />
                            </Container>
                        ),
                    },
                    {
                        path: "sogyo_sites",
                        element: <SogyoSites />,
                    },
                ],
            },
            {
                path: "mentoring/*",
                element: <AdminLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="/mentoring/tools" />,
                    },
                    {
                        path: "tools",
                        element: <MentoringMainPage />,
                    },
                    {
                        path: "tracks",
                        element: <TrackIndex />,
                        children: [
                            {
                                index: true,
                                element: <Overview />,
                            },
                            {
                                path: "edit/:id",
                                element: <EditTrack />,
                            },
                        ],
                    },
                    {
                        path: "trainees",
                        element: <TraineeIndex />,
                        children: [
                            {
                                index: true,
                                element: <Trainees />,
                            },
                            {
                                path: ":trainee",
                                element: <Trainee />,
                            },
                        ],
                    },
                    {
                        path: "blog_posts",
                        element: <BlogPostIndex />,
                        children: [
                            {
                                index: true,
                                element: <CreateBlogPost />,
                            },
                            {
                                path: ":blog_post",
                                element: <EditBlogPost />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "admin/*",
                element: <AdminLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="/admin/roadmap" />,
                    },
                    {
                        path: "roadmap",
                        element: <Roadmap />,
                    },
                    {
                        path: "users",
                        element: <Users />,
                    },
                    {
                        path: "events",
                        element: <Events />,
                    },
                    {
                        path: "projectors",
                        element: <ProjectorOverview />,
                    },
                ],
            },
        ],
    },
];

const router = createBrowserRouter(routes);

export const Routes = () => {
    return <RouterProvider router={router} />;
};
