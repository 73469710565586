import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { User } from "@/modules/admin/user_administration/User";
import { useUsersViewModel } from "@/modules/admin/user_administration/viewModels/useUsersViewModel";
import { LoadingButton } from "@mui/lab";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { NewUser } from "@/modules/admin/user_administration/NewUser";
import { useState } from "react";

export const Users = () => {
    const viewModel = useUsersViewModel();
    const [openNewUser, setOpenNewUser] = useState(false);
    const [userDialogKey, setUserDialogKey] = useState(1);

    const openNewUserDialog = () => {
        setOpenNewUser(true);
    };

    const closeNewUserDialog = () => {
        setUserDialogKey((key) => key + 1);
        setOpenNewUser(false);
    };

    return (
        <Container component="main" maxWidth={false}>
            <LoadingButton
                variant="contained"
                startIcon={<CloudSyncIcon />}
                loading={viewModel.isImporting}
                onClick={() => viewModel.importFromAurorus()}>
                Import from aurorus
            </LoadingButton>

            <NewUser open={openNewUser} key={userDialogKey} onClose={() => closeNewUserDialog()} />

            <Button color="secondary" startIcon={<PersonAddIcon />} onClick={() => openNewUserDialog()}>
                Add external user
            </Button>

            <TableContainer component={Paper}>
                <Table data-cy="UserTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>E-mail address</TableCell>
                            <TableCell>E-mail address verified?</TableCell>
                            <TableCell>Administrator?</TableCell>
                            <TableCell>Trainee?</TableCell>
                            <TableCell>Reset password</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewModel.users.map((user) => (
                            <User key={user.id} user={user} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};
