import { EbooksFolderId } from "@/constants";
import { Link } from "@/modules/academy/Link";
import { Alert, Box, Typography } from "@mui/material";

export function Library() {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Alert severity="error">
                <Typography>Google drive could not be reached.</Typography>
                <Link href={`https://drive.google.com/drive/u/0/folders/${EbooksFolderId}`}>Go to Google Drive</Link>
            </Alert>
        </Box>
    );
}
