import React from "react";

export const TaskListHeader: React.FC = () => {
    const headerHeight = 10;
    const listCellWidth = "155px";

    return (
        <div
            className="task-list-header-module-ganttTable"
            style={{
                display: "table",
                width: "100%",
                borderCollapse: "collapse",
                height: `${headerHeight}px`,
            }}>
            <div className="task-list-header-module-ganttTableHeader">
                <div
                    className="task-list-header-module-ganttTableHeaderItem task-list-header-module-ganttTableHeaderSeparator"
                    style={{
                        minWidth: listCellWidth,
                        borderRight: "1px solid rgb(196, 196, 196)",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        textAlign: "center",
                    }}>
                    Trainee(s)
                </div>
                <div
                    className="task-list-header-module-ganttTableHeaderItem task-list-header-module-ganttTableHeaderSeparator"
                    style={{
                        minWidth: listCellWidth,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        textAlign: "center",
                    }}>
                    Mentors
                </div>
                <div
                    className="task-list-header-module-ganttTableHeaderItem task-list-header-module-ganttTableHeaderSeparator"
                    style={{
                        minWidth: listCellWidth,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        textAlign: "center",
                    }}>
                    Achtergrond
                </div>
                <div
                    className="task-list-header-module-ganttTableHeaderItem task-list-header-module-ganttTableHeaderSeparator"
                    style={{
                        minWidth: listCellWidth,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        textAlign: "center",
                    }}>
                    Proposed Secondment
                </div>
            </div>
        </div>
    );
};
