import {
    AllUsersQueryResult,
    useGrantAdministratorRole,
    useResetPassword,
    useRevokeAdministratorRole,
    useUnverifyEmailAddress,
    useVerifyEmailAddress,
} from "@/services/message-service";
import { ArrayElement } from "@/types/arrayElement";

type User = ArrayElement<AllUsersQueryResult>;

export const useUserViewModel = (user: User) => {
    const grant = useGrantAdministratorRole();
    const revoke = useRevokeAdministratorRole();
    const verify = useVerifyEmailAddress();
    const unverify = useUnverifyEmailAddress();
    const resetPassword = useResetPassword();

    return {
        verifyAsync: async (emailAddress: string) => {
            user.emailAddressVerificationStatus = "Verified";

            try {
                await verify.mutateAsync({ id: user.id, emailAddress: emailAddress });
            } catch (error) {
                user.emailAddressVerificationStatus = "Unverified";
            }
        },
        unverifyAsync: async () => {
            user.emailAddressVerificationStatus = "Unverified";
            await unverify.mutateAsync({ id: user.id });
        },
        grantAdminRoleAsync: async () => {
            user.hasAdminRole = true;
            await grant.mutateAsync({ id: user.id });
        },
        revokeAdminRoleAsync: async () => {
            user.hasAdminRole = false;
            await revoke.mutateAsync({ id: user.id });
        },
        resetPasswordAsync: async (password: string) => {
            return await resetPassword.mutateAsync({
                id: user.id,
                password: password,
            });
        },
    };
};
